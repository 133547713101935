import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {closableNotification} from "../notification/ClosableNotification";
import LeftSidebarGamesList from "./Games/LeftSidebarGamesList";
import LeftSidebarChat from "./Chat/LeftSidebarChat";

import games from "../../../assets/images/leftSidebar/menu-games-icon.svg";
import chat from "../../../assets/images/leftSidebar/menu-chat-icon.svg";
import cons from "../../../assets/images/leftSidebar/menu-fair-icon.svg";
import stats from "../../../assets/images/leftSidebar/menu-stats-icon.svg";
import tokenBonus from "../../../assets/images/leftSidebar/menu-token-icon.svg";
import moderatorBonus from "../../../assets/images/leftSidebar/menu-faucet-icon.svg";
import bonus from "../../../assets/images/leftSidebar/menu-bonus-icon.svg";
import exchange from "../../../assets/images/leftSidebar/menu-exchanger-icon.svg";

import {defaultCurrentStatistics, LeftSidebarMenu, privileges, responseStatus, roles} from "../../../utils/consts";

import {
    StyledBonusButton,
    StyledChatButton,
    StyledControlButton, StyledExchangeButton, StyledFaucetsButton,
    StyledGamesButton,
    StyledLeftSidebarActions,
    StyledLeftSidebarContainer,
    StyledLeftSidebarWrapper,
    StyledStatsButton,
    StyledTokenButton,
} from "./styledLeftSidebar";
import LeftSidebarStatisticsContainer from "./Statistics/LeftSidebarStatisticsContainer";
import {useBetween} from "use-between";
import LeftSidebarHonestyControlContainer from "./HonestyControl/LeftSidebarHonestyControlContainer";
import LeftSideBarTokenBonusContainer from "./TokenBonus/LeftSideBarTokenBonusContainer";
import {VisibleLeftSidebarStates} from "./VisibleLeftSidebarStates";
import {AppContext} from "../../../App";
import BurgerStates from "../mobileNavigation/BurgerStates";
import BonusModal from "./Bonus/BonusModal";
import BonusRulesModal from "./BonusRules/BonusRulesModal";
import PayoutInvoice from "../payout/PayoutInvoice";
import Dialog from "rc-dialog";
import BalanceStates from "../../games/BalanceStates";
import {StyledButton} from "../../styles/styledButton";
import PaymentInvoice from "../payment/PaymentInvoice";
import http from "../../../http";
import userAuthenticationConfig from "../../../utils/userAuthenticationConfig";
import CurrentStatisticStates from "../../games/CurrentStatisticStates";
import {useLocation} from "react-router-dom";
import ConfirmationPayoutModal from "./ConfirmationPayout/ConfirmationPayoutModal";
import ExchangeModal from "./ExchangeModal /ExchangeModal";
import FaucetsModal from "./Faucets/FaucetsModal";
import axios from "axios";

const getClassName = (isActive) => {
    return isActive ? {className: "active"} : undefined;
};

const LeftSidebar = React.memo(({isNavigation = false, paymentMethod, setSubmitData, mobileNavBtnIsHidden = false}) => {
    const {
        isOpenBurger,
        setIsOpenBurger,
    } = useBetween(BurgerStates);
    const location = useLocation();

    const {
        visible,
        setVisible,
        menu,
        setMenu,
        message,
        tokenBonusVisible,
        setTokenBonusVisible,
        gameBonusVisible,
        setGameBonusVisible,
        rulesBonusVisible,
        setRulesBonusVisible,
        faucetsVisible,
        setFaucetsVisible,
        exchangeVisible,
        setExchangeVisible,
        faucetsStatusData,
        setFaucetsStatusData
    } = useBetween(VisibleLeftSidebarStates);

    const {
        setPaymentMethods,
        balance,
        setBalance,
        setActiveCurrency,
        paymentDialogVisible,
        setPaymentDialogVisible,
        payoutDialogVisible,
        setPayoutDialogVisible,
        confirmationVisible,
        setConfirmationVisible
    } = useBetween(BalanceStates);
    const { setCurrency } = useBetween(CurrentStatisticStates);

    const {t} = useTranslation("leftSidebar");
    const { t: sT } = useTranslation("siteOptions");
    const { t: tG } = useTranslation("games");

    const {authenticated, user, userPrivileges} = useContext(AppContext);

    const [payoutSubmitData, setPayoutSubmitData] = useState({
        amount: 0,
        paymentMethod: paymentMethod,
        attributes: [],
        code: null,
        payoutFee: null,
    });
    const [currentTime, setCurrentTime] = useState(Math.floor(Date.now() / 1000));

    const showMenu = useCallback((type) => {
        setVisible(true);
        setMenu(type);

        setIsOpenBurger(false)

        if (menu === type) {
            setVisible(false);
            setMenu("");
        }

    }, [menu, setMenu, setVisible]);

    useEffect(() => {
        if (visible) {
            window.history.pushState({modal: true}, '');

            const handlePopState = () => {
                setVisible(false);
                setMenu("");
            };

            window.addEventListener('popstate', handlePopState);
            return () => window.removeEventListener('popstate', handlePopState);
        }
    }, [visible, setVisible]);

    const getFaucetsStatusReq = () => {
        axios
            .get(`${process.env.REACT_APP_MODERATOR_LINK}/users/get-faucets-status`, userAuthenticationConfig())
            .then((response) => {
                if (response.status === responseStatus.HTTP_OK) {
                    setFaucetsStatusData(response.data);
                }
            })
            .catch((error) => {
                closableNotification(error.response.data.message, "error");
            });
    };

    useEffect(() => {
        if (authenticated && ((user?.roles[0] === roles.MODERATOR || user?.roles[0] === roles.ADMIN) || userPrivileges?.some(prev => prev?.type === privileges.LEGEND) || userPrivileges?.some(prev => prev?.type === privileges.VIP))) {
            if (!faucetsVisible) setFaucetsStatusData([]);
            getFaucetsStatusReq();
        }
    }, [faucetsVisible, authenticated]);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(Math.floor(Date.now() / 1000));
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    const formatTime = (seconds) => {
        const hrs = Math.floor(seconds / 3600);
        const mins = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${hrs > 0 ? String(hrs).padStart(2, "0") + ":" : ""}${String(mins).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
    };

    const getFaucetLabel = () => {
        if (!faucetsStatusData || faucetsStatusData.length === 0) {
            return t("faucet");
        }

        const bonusAvailable = faucetsStatusData.some(item => (item.next_bonus_time - currentTime) <= 1);

        if (bonusAvailable) {
            return t("faucet");
        } else {
            const remainingTimes = faucetsStatusData
                .map(item => item.next_bonus_time - currentTime)
                .filter(time => time > 0);
            const minRemaining = remainingTimes.length > 0 ? Math.min(...remainingTimes) : 0;
            return formatTime(minRemaining);
        }
    };

    const renderLeftSidebar = useMemo(() => {
        switch (menu) {
            case LeftSidebarMenu.GAMES:
                return (
                    <LeftSidebarGamesList
                        setVisible={setVisible}
                        setMenu={setMenu}
                    />
                );
            case LeftSidebarMenu.CHAT:
                return (
                    <LeftSidebarChat
                        setVisible={setVisible}
                        setMenu={setMenu}
                        visible={visible}
                        message={message}
                    />
                );
            case LeftSidebarMenu.STATISTICS:
                return (
                    <LeftSidebarStatisticsContainer
                        setVisible={setVisible}
                        setMenu={setMenu}
                        user={user}
                    />
                );
            case LeftSidebarMenu.CONTROL:
                return (
                    <LeftSidebarHonestyControlContainer
                        setVisible={setVisible}
                        setMenu={setMenu}
                        user={user}
                    />
                );
            default:
                return null;
        }
    }, [menu, setVisible, setMenu, visible, message, user]);

    const handleChangeTokenBonusVisible = () => {
        if (!authenticated) {
            closableNotification("Full authentication is required to access this resource.", "error");
        } else {
            setTokenBonusVisible(true);
            setIsOpenBurger(false);
        }
    };

    useEffect(() => {
        const root = document.getElementById("root");
        const isRoulettePage = location.pathname === '/roulette';

        if (window.innerWidth > 1100) {
            if (visible && !isRoulettePage) {
                root.className = "landslideLeft";
            } else {
                root.className = "";
            }
        }
    }, [visible]);

    useEffect(() => {
        const onResize = () => {
            const root = document.getElementById("root");
            root.className = "";
        };
        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, []);

    const selectPaymentMethod = useCallback((value) => {
        if (authenticated) {
            localStorage.setItem("paymentMethod", JSON.stringify(value));
            localStorage.setItem("currentStatistics", JSON.stringify(defaultCurrentStatistics));
        }
        setCurrency(value.currency.asset);
        setActiveCurrency(value.currency.asset);

        setBalance(value.balances ? value.balances.amount : 0);

        setSubmitData((prevState) => ({
            ...prevState,
            paymentMethod: value
        }));
    }, [authenticated, setActiveCurrency, setBalance, setCurrency, setSubmitData]);

    const getPaymentMethod = useCallback(() => {
        http.get("/api/payment-methods", userAuthenticationConfig()).then((response) => {
            if (response.status === responseStatus.HTTP_OK) {
                setPaymentMethods(response.data["hydra:member"]);

                if (!localStorage.getItem("paymentMethod") || localStorage.getItem("paymentMethod") === "undefined") {
                    const luckyCoinData = response?.data["hydra:member"]?.find(item => item?.name_view === "LUCKY");
                    if (luckyCoinData) {
                        selectPaymentMethod(luckyCoinData);
                    } else selectPaymentMethod(response.data["hydra:member"][0]);
                } else {
                    selectPaymentMethod(getActuallyPaymentData(response.data["hydra:member"]));
                }
            }
        }).catch((error) => {
            if (error?.response?.status === responseStatus.HTTP_BAD_REQUEST) {
                closableNotification(error.response.data.error, "error");
            }
        });
    }, [selectPaymentMethod, setPaymentMethods]);

    const getActuallyPaymentData = (paymentMethodData) => {
        let paymentMethod = JSON.parse(localStorage.getItem("paymentMethod"));

        paymentMethodData.forEach(actuallyPaymentMethod => {
            if (paymentMethod.id === actuallyPaymentMethod.id) {
                paymentMethod.balances = actuallyPaymentMethod.balances;
            }
        });

        return paymentMethod;
    };

    const closePayoutConfirmationDialog = () => {
        setPayoutDialogVisible(false);
        setConfirmationVisible(false);
    }

    return (
        <StyledLeftSidebarWrapper
            visible={visible}
            isNavigation={isNavigation}
        >
            <StyledLeftSidebarContainer id="leftSidebar">
                {renderLeftSidebar}
            </StyledLeftSidebarContainer>
            <StyledLeftSidebarActions
                isNavigation={isNavigation}
            >
                {!mobileNavBtnIsHidden ? <ul>
                    <StyledGamesButton
                        {...getClassName(menu === LeftSidebarMenu.GAMES)}
                        onClick={() => showMenu(LeftSidebarMenu.GAMES)}
                        isNav={isNavigation}
                    >
                        <img
                            src={games}
                            alt="games"
                            width={38}
                        />
                        <span>{t("games")}</span>
                    </StyledGamesButton>
                    {!isNavigation ?
                        <StyledChatButton
                            {...getClassName(menu === LeftSidebarMenu.CHAT)}
                            onClick={() => showMenu(LeftSidebarMenu.CHAT)}
                        >
                            <img
                                src={chat}
                                alt="chat"
                                width={30}
                            />
                            <span>{t("chat")}</span>
                        </StyledChatButton> : ""}
                    <StyledControlButton
                        {...getClassName(menu === LeftSidebarMenu.CONTROL)}
                        onClick={() => showMenu(LeftSidebarMenu.CONTROL)}
                        isNav={isNavigation}
                    >
                        <img
                            src={cons}
                            alt="cons"
                            width={30}
                        />
                        <span>{t("control")}</span>
                    </StyledControlButton>
                    {
                        authenticated ? <StyledStatsButton
                                {...getClassName(menu === LeftSidebarMenu.STATISTICS)}
                                onClick={() => showMenu(LeftSidebarMenu.STATISTICS)}
                                isNav={isNavigation}
                            >
                                <img
                                    src={stats}
                                    alt="stats"
                                    width={30}
                                />
                                <span>{t("stats")}</span>
                            </StyledStatsButton> :
                            null
                    }
                    {
                        authenticated ? <><StyledBonusButton
                                {...getClassName(menu === LeftSidebarMenu.BONUS_MODAL)}
                                onClick={() => {
                                    setGameBonusVisible(true);
                                    setIsOpenBurger(false);
                                }}
                                isNav={isNavigation}
                            >
                                <img
                                    src={bonus}
                                    alt="bonus"
                                    width={30}
                                />
                                <span>{t("bonus")}</span>
                            </StyledBonusButton>
                                {!isNavigation ? <BonusModal
                                    setVisible={setGameBonusVisible}
                                    user={user}
                                    visible={gameBonusVisible}
                                    setVisibleRules={setRulesBonusVisible}
                                /> : null}
                            </>
                            : null
                    }
                    {
                        authenticated ? <>
                                <StyledTokenButton
                                    {...getClassName(menu === LeftSidebarMenu.TOKEN_BONUS)}
                                    onClick={handleChangeTokenBonusVisible}
                                    isNav={isNavigation}
                                >
                                    <img
                                        src={tokenBonus}
                                        alt="tokenBonus"
                                        width={29}
                                    />
                                    <span>{t("tokenBonus")}</span>
                                </StyledTokenButton>
                                {!isNavigation ?
                                    <LeftSideBarTokenBonusContainer visible={tokenBonusVisible}
                                                                    setVisible={setTokenBonusVisible} t={t}/> : null}
                            </>
                            :
                            null
                    }
                    {
                        authenticated ? <>
                            <StyledExchangeButton
                                {...getClassName(menu === LeftSidebarMenu.EXCHANGE_MODAL)}
                                onClick={() => {
                                    setExchangeVisible(true);
                                    setIsOpenBurger(false);
                                }}
                                isNav={isNavigation}>
                                <img
                                    src={exchange}
                                    alt="exchange"
                                    width={30}
                                />
                                <span>{t("exchange")}</span>
                            </StyledExchangeButton>
                            {!isNavigation ? <ExchangeModal
                                setVisible={setExchangeVisible}
                                user={user}
                                visible={exchangeVisible}
                            /> : null}</> : null
                    }
                    {
                        authenticated && ((user?.roles[0] === roles.MODERATOR || user?.roles[0] === roles.ADMIN) || userPrivileges?.some(prev => prev?.type === privileges.LEGEND) || userPrivileges?.some(prev => prev?.type === privileges.VIP)) ? <>
                                <StyledFaucetsButton
                                    {...getClassName(menu === LeftSidebarMenu.FAUCETS_MODAL)}
                                    onClick={() => {
                                        setFaucetsVisible(true);
                                        setIsOpenBurger(false);
                                    }}
                                    isNav={isNavigation}
                                >
                                    <img
                                        src={moderatorBonus}
                                        alt="faucet"
                                        width={29}
                                    />
                                    <span>{ getFaucetLabel() }</span>
                                </StyledFaucetsButton>
                                {!isNavigation ?
                                    <FaucetsModal faucetsStatusData={faucetsStatusData} visible={faucetsVisible} userPrivileges={userPrivileges} getFaucetsStatusReq={getFaucetsStatusReq}
                                                                    setVisible={setFaucetsVisible} t={t}/> : null}
                            </>
                            :
                            null
                    }
                    {authenticated ? <BonusRulesModal visible={rulesBonusVisible} setVisible={setRulesBonusVisible}
                                                      setBonusVisible={setGameBonusVisible}/> : null}
                </ul> : null }
                {paymentMethod && setSubmitData && <>
                    <Dialog
                        visible={payoutDialogVisible}
                        wrapClassName={`default-modal-wrapper payout-modal`}
                        onClose={closePayoutConfirmationDialog}
                        animation="zoom"
                        maskAnimation="fade"
                        title={tG("titleBringOut")}
                        forceRender={false}
                        className="default-modal"
                    >
                        <PayoutInvoice
                            balance={balance}
                            paymentMethod={paymentMethod}
                            setVisible={setPayoutDialogVisible}
                            visible={payoutDialogVisible}
                            setConfirmationVisible={setConfirmationVisible}
                            payoutSubmitData={payoutSubmitData}
                            setPayoutSubmitData={setPayoutSubmitData}
                        />
                    </Dialog>
                    <Dialog
                        visible={paymentDialogVisible}
                        wrapClassName="default-modal-wrapper payment-modal"
                        onClose={() => setPaymentDialogVisible(false)}
                        animation="zoom"
                        maskAnimation="fade"
                        destroyOnClose={true}
                        footer={<StyledButton
                            onClick={() => setPaymentDialogVisible(false)}
                            color="neutral"
                            type="submit"
                            width="100"
                        >
                            {sT("close")}
                        </StyledButton>}
                        title={tG("titleFillIn")}
                        forceRender={false}
                        className="default-modal"
                    >
                        <PaymentInvoice
                            paymentMethod={paymentMethod}
                            selectPaymentMethod={selectPaymentMethod}
                            getPaymentMethod={getPaymentMethod}
                            setVisible={setPaymentDialogVisible}
                            visible={paymentDialogVisible}
                        />
                    </Dialog></>}
                {confirmationVisible && <ConfirmationPayoutModal visible={confirmationVisible} setVisible={setConfirmationVisible}
                                          payoutData={payoutSubmitData} closePayoutConfirmationDialog={closePayoutConfirmationDialog}
                                                                 setPayoutData={setPayoutSubmitData}/>}
            </StyledLeftSidebarActions>
        </StyledLeftSidebarWrapper>
    );
});

export default LeftSidebar;
