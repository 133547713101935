import styled from "styled-components";
import arrowDown from "../../../../assets/images/arrow-down.svg";

export const StyledMenuNav = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  color: #555555;
`;

export const StyledTopTabs = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  justify-content: center;
  
  .tabs-titles {
    font-size: 16px;
    display: flex;
    text-transform: uppercase;

      .all-tokens {
          position: relative;
          font-size: 14px;
          text-transform: uppercase;
          transition: all 0.15s ease-in-out;
          user-select: none;
          display: flex;
          align-items: center;

          & {
              color: ${({theme}) => theme.text};
          }

          &-title {
              cursor: pointer;
              display: flex;
              align-items: center;
              padding-right: 20px;
              margin-right: -20px;
              border-bottom: none !important;

              &:after {
                  content: "";
                  display: block;
                  background: url(${arrowDown}) no-repeat center;
                  width: 10px;
                  height: 7px;
                  margin-left: 6px;
                  transition: all 0.15s ease-in-out;
              }
          }
          &-title:after {
              border-bottom: 2px solid #999;
              border-right: 2px solid #999;
              content: '';
              display: block;
              height: 5px;
              margin-top: -4px;
              pointer-events: none;
              position: absolute;
              right: -12px;
              top: 50%;
              transform-origin: 66% 66%;
              transition: all 0.15s ease-in-out;
              width: 5px;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
          }
      }

      .active:after {
          rotate: -180deg;
      }

    .tabs-titles__item {
      margin-right: 25px;
      padding-bottom: 1px;
      cursor: pointer;
      color: ${({theme}) => theme.grayColorText};
      transition: all 0.15s ease-in-out;
      user-select: none;  
      
      &:hover {
        color: ${({theme}) => theme.text};
      }
    }
      .tabs-titles__item:last-child {
          margin-right: 0;
      }  
    .active {
      color: ${({theme}) => theme.text};
      border-bottom: 1px solid #333333;
      border-bottom: 1px solid ${({ theme }) => theme.text};

      &:hover {
        color: ${({theme}) => theme.text};
      }
    }
  }

  @media screen and (max-width: 630px) {
    .tabs-titles {
            .tabs-titles__item {
                margin-right: 15px;
            }
        }
    }

    @media screen and (max-width: 543px) {
        .tabs-titles {
            flex-wrap: wrap;
            justify-content: center;
        }
        .tabs-titles > div {
            margin-top: 15px;
        }
    }

    @media screen and (max-width: 372px) {
        .tabs-titles {
            .tabs-titles__item {
                font-size: 12px;
            }
        }
    }

    @media screen and (max-width: 328px) {
        .tabs-titles {
            .tabs-titles__item {
                font-size: 10px;
            }
        }
    }
`;
export const StyledModalLoader = styled.div`
    min-height: 500px;

    @media screen and (max-width: 1100px) {
        min-height: 467px;
    }

    @media screen and (max-width: 576px) {
        min-height: 457px;
    }

    @media screen and (max-width: 480px) {
        min-height: 407px;
    }
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 460px;
}
`;

export const StyledBodyTabs = styled.div`
    min-height: 500px;

    @media screen and (max-width: 1100px) {
        min-height: 467px;
    }

    @media screen and (max-width: 576px) {
        min-height: 457px;
    }

    @media screen and (max-width: 480px) {
        min-height: 407px;
    }
    
  .not-active {
    display: none;
  }
  
  .active {
    display: block;
  }
`;