import styled, { keyframes } from "styled-components";
import { StyledSpan } from "../../../styles/styledTypography";

export const Container = styled.div`
`;

export const StyledVipBonusWrapper = styled.div`
`;


export const StyledTimer = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.text};
    font-size: 24px;
`

export const StyledDaysBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  margin-bottom: 20px;
  
  @media (max-width: 576px) {
    gap: 5px;
  }
`;

export const StyledDayItem = styled.div`
  border-radius: 5px;
  overflow: hidden;
  text-align: center;
  padding-bottom: 15px;
  font-size: 14px;
  background-color: ${({ theme }) => theme.tokens.days.backgroundColor};
  opacity: ${({ hasDay }) => hasDay ? 1 : 0.4};

  ${({ active }) => active && `
    opacity: 1;
  `};

  @media (max-width: 576px) {
    font-size: 12px;
  }
}
`;

export const StyledDayItemHeader = styled.div`
  @keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  line-height: 23px;
  text-align: left;
  margin-bottom: 10px;
  position: relative;
  background: ${({ theme }) => theme.tokens.days.notFilled};
  border: 1px solid ${({ theme }) => theme.tokens.days.filled};
  color: #555;
  border-radius: 5px 5px 0 0;
  transition: background 0.5s ease;

  &:before {
    content: "";
    position: absolute;
    width: ${({ fillPercentage }) => `${fillPercentage}%`};
    height: 100%;
    background: ${({ currentDayStyle, prevDayStyle, theme }) => {
    if (currentDayStyle) {
        return `${theme.tokens.days.filled}`;
    }

    if (prevDayStyle) {
        return `${theme.tokens.days.filled}`;
    }
}};
    border-right: 1px solid ${({ theme, currentDayStyle }) => currentDayStyle ? theme.tokens.days.filled : "inherit"};
    background-size: 200% 100%;
    animation: gradientAnimation 10s linear infinite;
    top: 0;
  }

  ${StyledSpan} {
    position: relative;
    display: block;
    text-align: center;
    z-index: 10;
  }
`;

export const StyledDayItemAmount = styled.div`
  b {
    display: block;
    padding-bottom: 4px;
    font-weight: 500;
    font-size: 24px;
    color: ${({ hasDay, theme }) => hasDay ? theme.tokens.days.amountCurrDay : theme.tokens.days.amountOtherDay};
  }

  @media (max-width: 576px) {
    b {
      font-size: 16px;
    }
  }
`;

export const StyledAlertAmountUser = styled.div`
  padding: 13px 40px 13px 20px;
  border-left: 5px solid #2eac5b;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.tokens.notificationAmountToken.backgroundColor};
  color: ${({ theme }) => theme.tokens.notificationAmountToken.textColor};
  position: relative;
  margin-bottom: 20px;
`;

export const StyledHelpElementAlertAmountUser = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  right: 6px;
  margin-top: -16px;
  background-color: ${({ theme }) => theme.tokens.notificationAmountToken.iconBackground};
  border-radius: 100%;
  font-size: 21px;
  color: ${({ theme }) => theme.tokens.notificationAmountToken.iconColor};
  cursor: pointer;

  .help-el__text {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 250px;
    padding: 10px;
    background: ${({ theme }) => theme.tokens.notificationAmountToken.backgroundColor};
    font-size: 12px;
  }

  &:hover {
    .help-el__text {
      display: block;
    }
  }
`;