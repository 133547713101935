import Dialog from "rc-dialog";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import LoadButton from "../../../elements/spinner/ButtonSpinner";
import {StyledButton} from "../../../styles/styledButton";
import {useBetween} from "use-between";
import ProfileActionsStates from "../../ProfileActionsStates";
import axios from "axios";
import userAuthenticationConfig from "../../../../utils/userAuthenticationConfig";
import {closableNotification} from "../../../elements/notification/ClosableNotification";
import {
    StyledItemTable,
    StyledItemTitle,
    StyledItemValue,
    StyledWrapperTable
} from "./styledConfirmBanModal";
import getBanTimeVariants from "../../../../constants/banTimeVariants";
import UserTag, {tagRegex as userTagRegex} from "../../../elements/tags/UserTag";
import {renderToStaticMarkup} from "react-dom/server";
import BetTag, {tagRegex as betTagRegex} from "../../../elements/tags/BetTag";
import CurrencyTag, {tagRegex as currencyTagRegex} from "../../../elements/tags/CurrencyTag";
import EmojiTag, {tagRegex as emojiTagRegex} from "../../../elements/tags/EmojiTag";
import {decodeMessage} from "../../../elements/leftSidebar/Chat/utils";

function ConfirmBanModal({
                             activeNickname,
                             causeBan, setCause,
                             selectedVariantBanTime,
                         }, recaptchaRef) {
    const {t} = useTranslation("siteOptions");
    const {t: tE} = useTranslation('errors');

    const variantsBanTime = getBanTimeVariants(t);

    const [isLoading, setIsLoading] = useState(false);
    const {
        messageData,
        visibleConfirmBanModal,
        setVisibleConfirmBanModal,
        setVisibleBanUserModal
    } = useBetween(ProfileActionsStates);

    const banUserReq = () => {
        const data = {
            userNickname: activeNickname,
            causeBan: causeBan,
            timeBan: selectedVariantBanTime,
            messageId: messageData ? messageData.id : null,
            captchaToken: recaptchaRef?.current?.getValue()
        }
        setIsLoading(true);
        axios.post(`${process.env.REACT_APP_MODERATOR_LINK}/bans/ban-user`, data, userAuthenticationConfig(false)).then(response => {
            closableNotification(t("userBanned"), "success");
            setIsLoading(false);
            setCause("");
            setVisibleConfirmBanModal(false);
        }).catch(error => {
            closableNotification(tE(error.response.data.message), "error");
            setIsLoading(false);
        });
    };

    const processBeforeOutput = (value, user) => {
        if (typeof value === 'string') {
            value = value.replace(userTagRegex, (substr, username) => {
                const isCurrentUser = user === username;
                const hintData = !isCurrentUser ? {
                    "data-custom": true,
                    "data-custom-at": "right",
                    "data-id": window.crypto.getRandomValues(new Uint8Array(10)).join(''),
                    "data-nickname": username,
                } : {};
                return renderToStaticMarkup(<UserTag username={username} isCurrentUser={isCurrentUser}
                                                     hintData={hintData}/>);
            });
            value = value.replace(betTagRegex, (substr, id) => renderToStaticMarkup(<BetTag id={id}/>));
            value = value.replace(currencyTagRegex, (substr, coinName) => renderToStaticMarkup(
                <CurrencyTag coinSymbol={coinName}/>));
            value = value.replace(emojiTagRegex, (substr, emojiCode) => renderToStaticMarkup(<EmojiTag
                emojiCode={emojiCode}/>));
        }
        return decodeMessage(value);
    }

    return (
        <Dialog
            visible={visibleConfirmBanModal}
            wrapClassName="default-modal-wrapper"
            onClose={() => {
                setVisibleBanUserModal(true);
                setVisibleConfirmBanModal(false);
            }}
            animation="zoom"
            maskAnimation="fade"
            title={`${t("confirmBanTitle")} «${activeNickname}»`}
            forceRender={false}
            className="default-modal ban-user-modal"
            style={{margin: "auto"}}
        >
            <StyledWrapperTable>
                <StyledItemTable>
                    <StyledItemTitle>{t("nickname")}</StyledItemTitle>
                    <StyledItemValue>{activeNickname}</StyledItemValue>
                </StyledItemTable>
                <StyledItemTable>
                    <StyledItemTitle>{t("cause")}</StyledItemTitle>
                    <StyledItemValue>{causeBan}</StyledItemValue>
                </StyledItemTable>
                <StyledItemTable>
                    <StyledItemTitle>{t("timeBan")}</StyledItemTitle>
                    <StyledItemValue>{variantsBanTime?.find((variant) => variant.value === selectedVariantBanTime)?.title}</StyledItemValue>
                </StyledItemTable>
                <StyledItemTable>
                    <StyledItemTitle>{t("message")}</StyledItemTitle>
                    <StyledItemValue
                        dangerouslySetInnerHTML={{__html: processBeforeOutput(messageData?.value, activeNickname)}}
                        style={{maxWidth: "260px", display: "inline-block"}}/>
                </StyledItemTable>
            </StyledWrapperTable>
            {isLoading ? <LoadButton
                text={t("confirm")}
                color="neutral"
                type="submit"
                style={{width: '100%', margin: '0'}}
            /> : <StyledButton
                onClick={banUserReq}
                color="neutral"
                type="submit"
                style={{width: '100%', margin: '0'}}
                disabled={!selectedVariantBanTime || !causeBan || causeBan === ""}
            >
                {t("confirm")}
            </StyledButton>}
        </Dialog>
    )
}

export default ConfirmBanModal;