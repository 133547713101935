import axios from "axios";
import Cookies from "js-cookie";
import { useBetween } from "use-between";
import { responseStatus, TOPICS_LIST } from "../../../utils/consts";
import { generateJWSToken } from "../../../utils/mercureAuth";
import {
  formNotificationsArray,
  getStartingNotification,
  isVisibleNotifications,
  notificationsCookie,
  welcomeCookieName
} from "../../../utils/notifications";
import { TimestampToCustomFormat } from "../../../utils/timestampToDate";
import LanguageStates from "../listLanguage/LanguageStates";
import {
  StyledNotification,
  StyledNotificationsButton,
  StyledNotificationsWrapper,
  StyledRightSidebar
} from "./styledRightSidebar";
import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import notificationSounds from "../../../assets/sounds/newmessage.mp3";
import UserTag, { tagRegex as userTagRegex } from "../tags/UserTag";
import { renderToStaticMarkup } from "react-dom/server";
import BetTag, { tagRegex as betTagRegex } from "../tags/BetTag";
import { AppContext } from "../../../App";
import {useLayout} from "../../../utils/useLayout";
import Big from "big.js";
import BalanceStates from "../../games/BalanceStates";
import {io} from "socket.io-client";

export const RightSidebar = () => {

  const { t } = useTranslation("siteOptions");
  const [notifications, setNotifications] = useState([]);
  const [isNotificationsVisible, setIsNotificationsVisible] = useState((localStorage.getItem("isVisibleNotifications") && localStorage.getItem("isVisibleNotifications") !== "undefined") ? JSON.parse(localStorage.getItem("isVisibleNotifications")) : true);
  const [audio] = useState(new Audio(notificationSounds));
  const [notifiedIds, setNotifiedIds] = useState(() => {
    const storedIds = localStorage.getItem('notifiedIds');
    return (storedIds && storedIds !== "undefined") ? JSON.parse(storedIds) : [];
  });
  const [notificationHidden, setNotificationHidden] = useState(false);

  const { user, authenticated } = useContext(AppContext);
  const { activeLang } = useBetween(LanguageStates);
  const { isMobile } = useLayout();
  const {setBalance, setActiveCurrency, setPaymentMethods} = useBetween(BalanceStates);
  const [currentLangNotification, setCurrentLangNotification] = useState(activeLang);

  const socketRef = useRef(null);
  useEffect(() => {
    setCurrentLangNotification((prevLang) => {
        if (prevLang !== activeLang) {
          socketRef?.current?.emit("setLang", {lang: activeLang});
          return activeLang;
        }
        return prevLang;
    })
  }, [activeLang])

  const saveNotifiedIds = (ids) => {
    localStorage.setItem('notifiedIds', JSON.stringify(ids));
    setNotifiedIds(ids);
  };

  const deleteNotification = (id) => {
    socketRef.current.emit("delete", {id: id});
  };

  const handleCloseNotification = (event, id, type = null) => {
    setNotifications(old => old?.map((notification) => {
      if (notification.id === id) {
        notification.className.push("hidden");
      }
      const index = notification.className.indexOf("new");
      if (index >= 0) {
        notification.className.splice(index, 1);
      }
      return notification;
    }));

    if (type && type === "welcome") {
      Cookies.set(welcomeCookieName, true, { path: "" });
      setTimeout(() => {
        setNotifications(old => old?.filter(notification => notification.id !== id));
      }, 200);
      return;
    }

    deleteNotification(id);
  };

  const handleMinimizeNotification = (event, id) => {
    const newNotifications = notifications?.map((notification) => {
      if (notification.id === id) {
        const index = notification.className.indexOf("minimized");
        if (index >= 0) {
          notification.className.splice(index, 1);
        } else {
          notification.className.push("minimized");
        }
      }
      return notification;
    });

    setNotifications(newNotifications);

    Cookies.set(notificationsCookie, JSON.stringify(newNotifications));
  };

  const addNotification = (notification, isEnabledAudio = true) => {
    notification.className = ["new"];
    setNotifications(old => formNotificationsArray(old, [notification]));

    if (!notifiedIds.includes(notification.id)) {
      audio.play().catch(() => {});
      saveNotifiedIds([...notifiedIds, notification.id]);
    }
  };

  useEffect(() => {
    if (!Cookies.get(welcomeCookieName) || Cookies.get(welcomeCookieName) === "undefined" || Cookies.get(welcomeCookieName) !== "true") {
      const welcomeNotification = getStartingNotification(t);
      addNotification(welcomeNotification);
    }

  }, []);

  const handleOfflineNotifications = () => {
    let welcomeNotification = null;

    if (!Cookies.get(welcomeCookieName) || Cookies.get(welcomeCookieName) === "undefined" || Cookies.get(welcomeCookieName) !== "true") {
      welcomeNotification = getStartingNotification(t);
    }

    setNotifications(formNotificationsArray([], [welcomeNotification]));
  };

  const processBeforeOutput = (content) => {
    if (typeof content === "string") {
      content = content.replace(userTagRegex, (substr, username) =>
        renderToStaticMarkup(<UserTag username={username} isCurrentUser={user?.nickname === username} />));
      content = content.replace(betTagRegex, (substr, id) =>
        renderToStaticMarkup(<BetTag id={id} />));
    }
    return content;
  };

  const hideNotifications = () => {
    const newVisibleState = !isNotificationsVisible;

    setIsNotificationsVisible(newVisibleState);

    localStorage.setItem("isVisibleNotifications", JSON.stringify(newVisibleState));
  };

  const replaceExpirationWithFormattedDate = (message, locale = 'en') => {
    const regex = /((?:Expiration|Срок действия):\s*)(\d+)/;
    return message.replace(regex, (_, prefix, timestamp) => {
      const formatted = moment(parseInt(timestamp, 10) * 1000)
          .locale(locale)
          .format('LLL');
      return prefix + formatted;
    });
  };

  const getDataFromRainMessage = (content) => {
    const regex = /Amount:\s*([\d.]+)\s*([A-Z]+)/;
    const matches = content.match(regex);

    if (matches) {
      const amount = matches[1];
      const currency = matches[2];

      return {amount, currency};
    }
  }

  const topic = TOPICS_LIST.NOTIFICATION.TOPIC + user?.userId;
  const token = generateJWSToken(topic);

  const createSocketConnection = () => {
    const storedToken = localStorage.getItem("token");
    const token = "Bearer " + storedToken;

    socketRef.current = io("https://notifications.luckygames.app", {
      auth: storedToken ? {token} : {},
      query: { lang: activeLang },
      cors: {
        origin: window.location.origin,
        methods: ["GET", "POST", "OPTIONS"]
      },
      reconnection: true,
      path: process.env.REACT_APP_CHAT_TAG === "prod" ? "/socket.io" : "/dev/socket.io",
      credentials: true,
      transports: ["websocket"],
    });

    return socketRef.current;
  };

  function formatMinutes(minutes) {
    const units = [
      {label: t("botMessageWeeks"), value: 60 * 24 * 7},
      {label: t("botMessageDays"), value: 60 * 24},
      {label: t("botMessageHours"), value: 60},
      {label: t("botMessageMinutes"), value: 1}
    ];

    let result = [];

    for (const unit of units) {
      if (minutes >= unit.value) {
        const amount = Math.floor(minutes / unit.value);
        minutes %= unit.value;
        result.push(`${amount}${unit.label}`);
      }
    }

    return result.join(' ') || '0m';
  }

  function replaceBanDurationWithFormattedTime(message, locale = 'en') {
    const regex = /((?:Duration|Длительность):\s*)(\d+)/;
    return message.replace(regex, (_, prefix, minutesStr) => {
      const minutes = parseInt(minutesStr, 10);
      const formatted = formatMinutes(minutes);
      return prefix + formatted;
    });
  }

  useEffect(() => {
    if (!authenticated) {
      return;
    }

    const socket = createSocketConnection();

    socket.on("allNotifications", (data) => {
      data.forEach(notification => {
        if (notification.message && (notification.message.includes("Duration:") || notification.message.includes("Длительность:"))) {
          notification.message = replaceBanDurationWithFormattedTime(notification.message, activeLang);
        }
        if ((notification.message && (notification.message.includes("Expiration:") || notification.message.includes("Срок действия:"))) ||
            (notification.content && (notification.content.includes("Expiration:") || notification.content.includes("Срок действия:")))) {
            notification.message = replaceExpirationWithFormattedDate(notification.message, activeLang);
        }
      });
      setNotifications([]);
      setNotifications(old => formNotificationsArray(old, data ?? []));
    })

    socket.on("newNotification", (data) => {
      if (data.message && (data.message.includes("Duration:") || data.message.includes("Длительность:"))) {
        data.message = replaceBanDurationWithFormattedTime(data.message, activeLang);
      }
      if ((data?.content && (data.content.includes("Expiration:") || data.content.includes("Срок действия:"))) ||
          (data?.message && (data.message.includes("Expiration:") || data.message.includes("Срок действия:")))) {
          data.message = replaceExpirationWithFormattedDate(data.message, activeLang);
      }
      if (data?.title === "Rain") {
        const {amount, currency} = getDataFromRainMessage(data?.message);
        setActiveCurrency((prevActiveCurrency) => {
          if (prevActiveCurrency === currency) setBalance(prevState => {
              return (new Big(prevState ?? 0).plus(new Big(amount))).toFixed(8);
            })
            return prevActiveCurrency;
        })

        setPaymentMethods(prevState =>
            prevState?.map(paymentMethod => {
              if (paymentMethod?.currency?.asset === currency) {
                return {
                  ...paymentMethod,
                  balances: {
                    ...paymentMethod.balances,
                    amount: new Big(paymentMethod.balances.amount ?? 0).plus(new Big(amount)).toFixed(8)
                  }
                };
              }
              return { ...paymentMethod };
            })
        );
      }
      addNotification(data);
    });

    socket.on("delete", (data) => {
      setNotifications(old => {
        const updated = old.map(notification => {
          if (notification.id === data.id && !notification.className.includes("hidden")) {
            return { ...notification, className: [...notification.className, "hidden"] };
          }
          return notification;
        });
        setTimeout(() => {
          setNotifications(current => current.filter(notification => notification.id !== data.id));
        }, 200);
        return updated;
      });
    });

    return () => {
      socket.close();
    };
  }, [authenticated]);

  useEffect(() => {
    if (!authenticated) {
      handleOfflineNotifications();
      return;
    }

    setNotifications([]);
    }, [authenticated, activeLang]);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    const scrollThreshold = document.documentElement.scrollWidth <= 768 ? 200 : 88;
    const isNearBottom = (scrollPosition + windowHeight) >= (documentHeight - scrollThreshold);

    setNotificationHidden(isNearBottom);
  };

  useEffect(() => {
    const throttledScroll = () => {
      if (!window.requestAnimationFrame) {
        setTimeout(handleScroll, 66);
        return;
      }
      window.requestAnimationFrame(handleScroll);
    };

    window.addEventListener("scroll", throttledScroll);
    return () => window.removeEventListener("scroll", throttledScroll);
  }, []);

  const sortedNotifications = useMemo(() => {
    return notifications
        .slice()
        .sort((a, b) => {
          const timeA = a.created_at || a.createdAt;
          const timeB = b.created_at || b.createdAt;
          return timeA - timeB;
        });
  }, [notifications]);

  const notificationsComponent = useMemo(() => {
    return sortedNotifications?.map((notification, key) => {
      return <StyledNotification
        key={key}
        animationDuration={200}
        id={`notification_${notification.id}`}
        className={notification.className.join(" ") ?? ""}
      >
        <div className={"options"}>
          <div className={"minimize"} onClick={e => handleMinimizeNotification(e, notification.id)}>–</div>
          <div className={"close"} onClick={e => handleCloseNotification(e, notification.id, notification?.type)}>×
          </div>
        </div>
        <div className={"notification-title"}>{notification.title}</div>
        <div className={"notification-content"}
             dangerouslySetInnerHTML={{ __html: processBeforeOutput(notification.message ?? notification.content) }}
        />
        <div className={"notification-time"}>{TimestampToCustomFormat(notification.created_at ?? (notification.createdAt / 1000))}</div>
      </StyledNotification>;
    });
  }, [notifications]);

  return <>
    {notifications?.length > 0 ?
      <StyledRightSidebar className={`${notificationHidden ? "hidden_notification" : ""}`}>
        {isNotificationsVisible && notifications?.length > 0 && <StyledNotificationsWrapper>
          {notificationsComponent}
        </StyledNotificationsWrapper>}
        <StyledNotificationsButton
          onClick={hideNotifications}
          className={isNotificationsVisible || notifications?.length > 0 ? "active" : ""}
        >
          {isNotificationsVisible ? t("hideNotifications") : t("showNotifications")}
          {!isNotificationsVisible &&
            <span className={"notifications-counter"}>{notifications?.length}</span>
          }
        </StyledNotificationsButton>
      </StyledRightSidebar> :
      null
    }
  </>
};
