import {
    StyledBonusButton,
    StyledControlButton, StyledExchangeButton, StyledFaucetsButton,
    StyledGamesButton, StyledLeftSidebarActions,
    StyledStatsButton, StyledTokenButton,
} from "./styledLeftSidebar";
import {LeftSidebarMenu, privileges, responseStatus, roles} from "../../../utils/consts";
import games from "../../../assets/images/leftSidebar/menu-games-icon.svg";
import cons from "../../../assets/images/leftSidebar/menu-fair-icon.svg";
import stats from "../../../assets/images/leftSidebar/menu-stats-icon.svg";
import bonus from "../../../assets/images/leftSidebar/menu-bonus-icon.svg";
import tokenBonus from "../../../assets/images/leftSidebar/menu-token-icon.svg";
import moderatorBonus from "../../../assets/images/leftSidebar/menu-faucet-icon.svg";
import exchange from "../../../assets/images/leftSidebar/menu-exchanger-icon.svg";

import React, {useCallback, useContext, useEffect, useState} from "react";
import {useBetween} from "use-between";
import {VisibleLeftSidebarStates} from "./VisibleLeftSidebarStates";
import {useTranslation} from "react-i18next";
import {AppContext} from "../../../App";
import BurgerStates from "../mobileNavigation/BurgerStates";
import {closableNotification} from "../notification/ClosableNotification";
import axios from "axios";
import userAuthenticationConfig from "../../../utils/userAuthenticationConfig";

const getClassName = (isActive) => {
    return isActive ? {className: "active"} : undefined;
};

function MobileNavigationMenu() {

    const {t} = useTranslation("leftSidebar");

    const {
        setVisible,
        menu,
        setMenu,
        setTokenBonusVisible,
        setGameBonusVisible,
        setFaucetsVisible,
        setExchangeVisible,
        visible,
        faucetsVisible,
        faucetsStatusData,
        setFaucetsStatusData
    } = useBetween(VisibleLeftSidebarStates);
    const {setIsOpenBurger} = useBetween(BurgerStates);
    const {authenticated, user, userPrivileges} = useContext(AppContext);

    const [currentTime, setCurrentTime] = useState(Math.floor(Date.now() / 1000));

    const handleChangeTokenBonusVisible = () => {
        if (!authenticated) {
            closableNotification("Full authentication is required to access this resource.", "error");
        } else {
            setTokenBonusVisible(true);
            setIsOpenBurger(false);
        }
    };

    const showMenu = useCallback((type) => {
        setVisible(true);
        setMenu(type);

        setIsOpenBurger(false)

        if (menu === type) {
            setVisible(false);
            setMenu("");
        }
    }, [menu, setMenu, setVisible]);

    const getFaucetsStatusReq = () => {
        axios
            .get(`${process.env.REACT_APP_MODERATOR_LINK}/users/get-faucets-status`, userAuthenticationConfig())
            .then((response) => {
                if (response.status === responseStatus.HTTP_OK) {
                    setFaucetsStatusData(response.data);
                }
            })
            .catch((error) => {
                closableNotification(error.response.data.message, "error");
            });
    };

    useEffect(() => {
        if (authenticated && ((user?.roles[0] === roles.MODERATOR || user?.roles[0] === roles.ADMIN) || userPrivileges?.some(prev => prev?.type === privileges.LEGEND) || userPrivileges?.some(prev => prev?.type === privileges.VIP))) {
            getFaucetsStatusReq();
        }
    }, [visible, faucetsVisible]);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(Math.floor(Date.now() / 1000));
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    const formatTime = (seconds) => {
        const hrs = Math.floor(seconds / 3600);
        const mins = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${hrs > 0 ? String(hrs).padStart(2, "0") + ":" : ""}${String(mins).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
    };

    const getFaucetLabel = () => {
        if (!faucetsStatusData || faucetsStatusData.length === 0) {
            return t("faucet");
        }

        const bonusAvailable = faucetsStatusData.some(item => (item.next_bonus_time - currentTime) <= 0);
        if (bonusAvailable) {
            return t("faucet");
        } else {
            const remainingTimes = faucetsStatusData
                .map(item => item.next_bonus_time - currentTime)
                .filter(time => time > 0);
            const minRemaining = remainingTimes.length > 0 ? Math.min(...remainingTimes) : 0;
            return formatTime(minRemaining);
        }
    };

    return (
        <StyledLeftSidebarActions isNavigation>
            <ul>
                <StyledGamesButton {...getClassName(menu === LeftSidebarMenu.GAMES)}
                                   onClick={() => showMenu(LeftSidebarMenu.GAMES)} isNav>
                    <img src={games} alt="games" width={38}/>
                    <span>{t("games")}</span>
                </StyledGamesButton>
                <StyledControlButton {...getClassName(menu === LeftSidebarMenu.CONTROL)}
                                     onClick={() => showMenu(LeftSidebarMenu.CONTROL)} isNav>
                    <img src={cons} alt="cons" width={30}/>
                    <span>{t("control")}</span>
                </StyledControlButton>
                {authenticated ? <StyledStatsButton {...getClassName(menu === LeftSidebarMenu.STATISTICS)}
                                                    onClick={() => showMenu(LeftSidebarMenu.STATISTICS)} isNav>
                    <img src={stats} alt="stats" width={30}/>
                    <span>{t("stats")}</span>
                </StyledStatsButton> : null}
                {authenticated ? <>
                    <StyledBonusButton{...getClassName(menu === LeftSidebarMenu.BONUS_MODAL)}
                                      onClick={() => {
                                          setGameBonusVisible(true);
                                          setIsOpenBurger(false);
                                      }}
                                      isNav>
                        <img src={bonus} alt="bonus" width={30}/>
                        <span>{t("bonus")}</span>
                    </StyledBonusButton>
                </> : null}
                {authenticated ? <> <StyledTokenButton {...getClassName(menu === LeftSidebarMenu.TOKEN_BONUS)}
                                                       onClick={handleChangeTokenBonusVisible} isNav>
                    <img src={tokenBonus} alt="tokenBonus" width={29}/>
                    <span>{t("tokenBonus")}</span>
                </StyledTokenButton>
                </> : null}
                {authenticated ? <StyledExchangeButton {...getClassName(menu === LeftSidebarMenu.EXCHANGE_MODAL)}
                                                       onClick={() => {
                                                           setExchangeVisible(true);
                                                           setIsOpenBurger(false);
                                                       }} isNav>
                    <img src={exchange} alt="exchange" width={30}/>
                    <span>{t("exchange")}</span>
                </StyledExchangeButton> : null}
                {
                    authenticated && ((user?.roles[0] === roles.MODERATOR || user?.roles[0] === roles.ADMIN) || userPrivileges?.some(prev => prev?.type === privileges.LEGEND) || userPrivileges?.some(prev => prev?.type === privileges.VIP)) ? <>
                        <StyledFaucetsButton
                            {...getClassName(menu === LeftSidebarMenu.FAUCETS_MODAL)}
                            onClick={() => {
                                setFaucetsVisible(true);
                                setIsOpenBurger(false);
                            }}
                            isNav>
                            <img
                                src={moderatorBonus}
                                alt="faucet"
                                width={29}
                            />
                            <span>{ getFaucetLabel() }</span>
                        </StyledFaucetsButton>
                    </> : null}
            </ul>
        </StyledLeftSidebarActions>
    )
}

export default MobileNavigationMenu;
