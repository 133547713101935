import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {closableNotification} from "../../notification/ClosableNotification";
import Notification from "rc-notification";
import {AppContext} from "../../../../App";
import {lang} from "../../../../utils/getLanguage";
import Rain from "../../rain/Rain";
import Dialog from "rc-dialog";
import {
    StyledChatBetExample,
    StyledChatBottomActions,
    StyledChatBottomButtons,
    StyledChatBottomImage,
    StyledChatBottomInfo,
    StyledChatBottomInfoText,
    StyledChatBottomInput,
    StyledChatEmojiBlock,
    StyledChatEmojiIcon,
    StyledChatHeader,
    StyledChatHeaderActions,
    StyledChatHeaderLang,
    StyledChatHeaderMailImg,
    StyledChatHeaderUsersImg,
    StyledChatInfoBlock,
    StyledChatInfoColouredBox,
    StyledChatInfoRow,
    StyledChatInfoRowRight,
    StyledChatLoader,
    StyledChatText,
    StyledChatTitle,
    StyledChatWrapper,
    StyledLeftSidebarChatWrapper
} from "./styledSidebarChat";
import infoImg from "../../../../assets/images/chat/info-icon.svg";
import rainImg from "../../../../assets/images/chat/rain_icon.svg";
import smileImg from "../../../../assets/images/chat/emoji_icon.svg";
import loadingGif from "../../../../assets/images/loading.gif";

import ChatMessages from "./ChatMessages/ChatMessages";
import {StyledButton} from "../../../styles/styledButton";
import {chatEmoji} from "./chatEmoji";
import ChatRooms from "./ChatRooms/ChatRooms";
import {languages} from "../../../../utils/languages";
import {encodeMessage} from "./utils";
import ChatFriends from "./ChatFriends/ChatFriends";
import ChatConversations from "./ChatConversations/ChatConversations";
import {useBetween} from "use-between";
import {VisibleLeftSidebarStates} from "../VisibleLeftSidebarStates";
import CurrentStatisticsStates from "../../../games/CurrentStatisticStates";
import SendTipWindow from "../../../profileActionsWindow/SendTipWindow";
import ProfileActionsStates from "../../../profileActionsWindow/ProfileActionsStates";
import {io} from "socket.io-client";
import ChatCaptchaModal from "./ChatCaptchaModal/ChatCaptchaModal";
import {useLayout} from "../../../../utils/useLayout";

const LeftSidebarChat = ({visible, setVisible, setMenu, isNavigation}) => {
    const [room, setRoom] = useState(null);
    const [showEmoji, setShowEmoji] = useState(false);
    const [showRain, setShowRain] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [showRooms, setShowRooms] = useState(false);
    const [showCaptcha, setShowCaptcha] = useState(false);
    const [captchaSuccess, setCaptchaSuccess] = useState(false);
    const [rooms, setRooms] = useState(null);
    const [showFriends, setShowFriends] = useState(false);
    const [showConversations, setShowConversations] = useState(false);
    const [langIndex, setLangIndex] = useState(localStorage.getItem("langIndexForChatRoom") ?? languages.indexOf(lang()));
    const [loading, setLoading] = useState(true);
    const [sendMessageLoading, setSendMessageLoading] = useState(false);
    const [userIsBanned, setUserIsBanned] = useState(false);
    const [viewportHeight, setViewportHeight] = useState(0);
    const [isFocused, setIsFocused] = useState(false);
    const [isVisibleTab, setIsVisibleTab] = useState(true);
    const [reconnectCount, setReconnectCount] = useState(0);

    const textRef = useRef();
    const messagesContainerRef = useRef();
    const initialHeightRef = useRef(window.visualViewport?.height || window.innerHeight);

    const {
        message,
        setMessage,
        messages,
        setMessages,
        focus,
        setFocus,
        setChatIsClicked
    } = useBetween(VisibleLeftSidebarStates);
    const [onlineCount, setOnlineCount] = useState(0);

    const {incrementMessages} = useBetween(CurrentStatisticsStates);
    const {nickname, handleProfileActionsVisible} = useBetween(ProfileActionsStates);

    const {isMobile, orientation} = useLayout();
    const {user, authenticated} = useContext(AppContext);

    const [notification, setNotification] = useState({
        visible: false,
        type: "",
        message: ""
    });

    const {t} = useTranslation("leftSidebar");
    const {t: errorsT} = useTranslation("errors");

    const socketRef = useRef(null);
    const chatWrapperRef = useRef(null);

    const cleanupPreviousSocket = () => {
        if (socketRef.current) {
            socketRef.current.close();
            socketRef.current = null;
        }
    };

    useEffect(() => {
        const newHeight = window.visualViewport?.height || window.innerHeight;
        initialHeightRef.current = newHeight;
        setViewportHeight(newHeight);
        if (textRef?.current) textRef?.current.blur();
        setIsFocused(false);
    }, [orientation]);

    useEffect(() => {
        if (!isMobile) return;
        const handleResize = () => {
            const currentHeight = window.visualViewport?.height || window.innerHeight;
            const diff = initialHeightRef.current - currentHeight;
            if (diff === 0) return;
            let focused;

            setIsFocused((prevIsFocused) => {
                focused = prevIsFocused;
                return prevIsFocused;
            })

            setViewportHeight((prevState) => {
                if (prevState > window.visualViewport?.height || window.innerHeight) {
                    if ((prevState - window.visualViewport?.height || window.innerHeight) < window.innerHeight) {
                        if ((prevState - window.visualViewport?.height || window.innerHeight) > 0 && focused) {
                            document.querySelector('.chat-wrapper').style.paddingBottom = `${(prevState - window.visualViewport?.height || window.innerHeight)}px`;
                        } else document.querySelector('.chat-wrapper').style.paddingBottom = '0px';
                    } else document.querySelector('.chat-wrapper').style.paddingBottom = '0px';
                }
                return window.visualViewport?.height || window.innerHeight;
            })
        };

        if (window.visualViewport) {
            window.visualViewport.addEventListener('resize', handleResize);
            window.visualViewport.addEventListener('scroll', handleResize);
        } else {
            window.addEventListener('resize', handleResize);
        }

        return () => {
            if (window.visualViewport) {
                window.visualViewport.removeEventListener('resize', handleResize);
                window.visualViewport.removeEventListener('scroll', handleResize);
            } else {
                window.removeEventListener('resize', handleResize);
            }
        };
    }, []);

    const createSocketConnection = () => {
        const storedToken = localStorage.getItem("token");
        const token = "Bearer " + storedToken;

        socketRef.current = io(process.env.REACT_APP_CHAT_LINK, {
            auth: storedToken ? {token} : {},
            cors: {
                origin: window.location.origin,
                methods: ["GET", "POST", "OPTIONS"]
            },
            reconnection: true,
            path: process.env.REACT_APP_CHAT_TAG === "prod" ? "/socket.io" : "/dev/socket.io",
            credentials: true,
            transports: ["websocket"],
        });

        return socketRef.current;
    };

    const handleSendMessageErrors = (data) => {
        setSendMessageLoading(false);
        if (data.status === "ok") setMessage("");
        if (data.status === "error") {
            const errorHandlers = {
                1: () => closableNotification(errorsT("sendMessage_code_1"), "error"),
                2: () => closableNotification(errorsT("sendMessage_code_2"), "error"),
                3: () => closableNotification(errorsT("sendMessage_code_3"), "error"),
                4: () => closableNotification(errorsT("sendMessage_code_4"), "error"),
                5: () => closableNotification(errorsT("sendMessage_code_5"), "error"),
                6: () => closableNotification(errorsT("sendMessage_code_6"), "error"),
                7: () => handleErrorWhenSendMessageAndNoRoom(),
                8: () => setShowCaptcha(true),
                9: () => closableNotification(errorsT("sendMessage_code_9"), "error"),
            };

            errorHandlers[data.error_code]?.();
        }
    };

    const handleErrorWhenSendMessageAndNoRoom = async () => {
        setRoom((prevState) => {
            socketRef.current.emit("joinRoom", {room_id: prevState?.id});
            return prevState;
        })
        setTimeout(() => sendMessage(), 0);
    }

    const handleRoomsList = (listRooms) => {
        setLangIndex((prevLangIndex) => {
            setRooms(listRooms);
            setOnlineCount(listRooms.reduce((sum, item) => sum + item.online, 0));

            setRoom((prevState) => {
                if (!prevState) joinRoom(listRooms[prevLangIndex]?.id);
                return listRooms[prevLangIndex];
            });

            return prevLangIndex;
        });
    };

    const handleLastMessages = (lastMessages) => {
        setMessages(lastMessages.sort((a, b) => a.id - b.id));
        incrementMessages();
        setLoading(false);
        setTimeout(() => {
            messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
        }, 0)
    };

    useEffect(() => {
        if (socketRef?.current?.connected) return;
        if (!isVisibleTab && !socketRef?.current?.connected) return cleanupPreviousSocket();
        cleanupPreviousSocket();
        const socket = createSocketConnection();

        socket.on("connect", () => {
            socket.emit("checkToken");
        });

        socket.on("checkToken", (data) => {
            if (data.status !== "ok") socket.disconnect();
        });

        socket.on("sendMessage", handleSendMessageErrors);

        socket.on("joinRoom", (data) => {
            if (data.status === "error") {
                closableNotification(errorsT("joinRoom_error"), "error");
            }
        });

        socket.on("listRooms", handleRoomsList);
        socket.on("lastMessages", handleLastMessages);
        socket.on("newMessage", updateMessages);
        socket.on("ban", banUserHandler);
        socket.on("disconnect", () => {
            cleanupPreviousSocket();
        });

        return () => {
            socket.close();
            setRoom(null);
        };
    }, [authenticated, reconnectCount]);

    useEffect(() => {
        if (!socketRef?.current?.connected && isVisibleTab) {
            setReconnectCount(prevCount => prevCount + 1);
        }
    }, [isVisibleTab])

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (!document.hidden) {
                setIsVisibleTab(true);
            } else setIsVisibleTab(false);
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);

        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, []);

    const joinRoom = (roomId) => {
        setLoading(true);
        socketRef.current.emit("joinRoom", {room_id: roomId});
    }

    const sendMessage = () => {
        if (!authenticated) {
            closableNotification(errorsT("Full authentication is required to access this resource."), "error");
            return;
        }
        setShowEmoji(false);
        setSendMessageLoading(true);

        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = textRef.current.innerHTML.replaceAll("<br>", " ").trim();

        const emojiImages = tempDiv.getElementsByTagName('img');
        for (let img of Array.from(emojiImages)) {
            const emojiCode = img.getAttribute('data-code');
            if (emojiCode) {
                img.outerHTML = ` sticker:${emojiCode} `;
            }
        }

        const value = tempDiv.innerHTML.trim();

        const hasText = tempDiv.textContent.replaceAll("&nbsp;", " ").trim()?.length > 0;
        const hasImages = tempDiv.getElementsByTagName('img')?.length > 0;

        if (!hasText && !hasImages) {
            setSendMessageLoading(false);
            return;
        }

        const socket = socketRef.current;

        socket.emit("sendMessage", {value: encodeMessage(value)});
        if (textRef.current) {
            textRef.current.focus();
            setIsFocused(true);
        }
    };

    const banUserHandler = (message) => {
        if (!message?.is_banned) return setUserIsBanned(false);

        setUserIsBanned(true);

        const banDurationMs = message?.ban_time * 60 * 1000;
        const unbanTime = message?.banned_at * 1000 + banDurationMs;
        const now = Date.now();

        if (unbanTime > now) {
            setTimeout(() => {
                setUserIsBanned(false);
            }, unbanTime - now);
        } else {
            setUserIsBanned(false);
        }
    }

    const updateMessages = (message) => {
        if (message.type === "system delete_message") return deleteMessageFromChat(message);

        let currentRoomData;
        let currentMessagesData;

        setRoom((prevState) => {
            currentRoomData = prevState;
            return prevState;
        })

        setMessages((prevState) => {
            currentMessagesData = prevState.sort((a, b) => a.id - b.id);
            return prevState;
        })

        if (!currentRoomData || currentRoomData.id !== message.room_id && message.room_id !== "all") {
            return;
        }

        const index = currentMessagesData?.findIndex((p) => p.id === message.id);

        let values = currentMessagesData ?? [];

        if (index >= 0) {
            if (message.user?.mediaObject) {
                values[index].user = {...message.user};
            } else {
                return;
            }
        } else {
            values.push(message);
        }

        const isAtBottom = messagesContainerRef?.current?.scrollTop + messagesContainerRef?.current?.clientHeight >= messagesContainerRef?.current?.scrollHeight - 10;

        if ((messagesContainerRef?.current && message?.user?.nickname === user?.nickname) || (messagesContainerRef?.current && isAtBottom)) {
            handleProfileActionsVisible(null, "chat");
        }

        setMessages([...values]);

        if ((messagesContainerRef?.current && message?.user?.nickname === user?.nickname) || (messagesContainerRef?.current && isAtBottom)) {
            messagesContainerRef.current.scrollTo({
                top: messagesContainerRef.current.scrollHeight,
                behavior: 'smooth',
            });
        }
    };

    const deleteMessageFromChat = (message) => {
        setMessages((prevState) =>
            prevState?.filter(
                (msg) => !(msg.id === message.value.id && msg.room_id === message.room_id)
            )
        );
    }

    const resizeTextInput = () => {
        if (textRef.current) {
            textRef.current.style.height = 0;
            textRef.current.style.height = textRef.current.scrollHeight + "px";
        }
    };

    useEffect(() => {
        if (loading) return;
        if (messagesContainerRef?.current) {
            messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
        }
    }, [loading]);

    useEffect(() => {
        if (showCaptcha || !message || !captchaSuccess) return;
        sendMessage();
        setCaptchaSuccess(false);
    }, [showCaptcha])

    const toggleConversations = useCallback((value = true) => {
        if (authenticated) {
            setShowConversations(value);
        } else {
            closableNotification(errorsT("Full authentication is required to access this resource."), "error");
        }
    }, [authenticated, errorsT]);

    const toggleFriends = useCallback((value = true) => {
        if (authenticated) {
            setShowFriends(value);
        } else {
            closableNotification(errorsT("Full authentication is required to access this resource."), "error");
        }
    }, [authenticated, errorsT]);

    const toggleRain = useCallback((value = true) => {
        if (authenticated) {
            setShowRain(value);
        } else {
            closableNotification(errorsT("Full authentication is required to access this resource."), "error");
        }
    }, [authenticated, errorsT]);

    useEffect(() => {
        if (textRef.current && textRef.current.innerHTML !== message) {
            textRef.current.innerHTML = message;
            resizeTextInput();
            textRef.current.click();
        }
    }, [message, textRef.current]);

    useEffect(() => {
        if (textRef.current && focus) {
            const range = document.createRange();
            const selection = window.getSelection();

            range.selectNodeContents(textRef.current);
            range.collapse(false);

            selection.removeAllRanges();

            selection.addRange(range);
            setFocus(false);
        }
    }, [visible, textRef.current, focus]);

    const onInput = (event) => {
        resizeTextInput();

        if (textRef.current.innerHTML === "<br>") {
            setMessage("");
            return textRef.current.innerHTML = "";
        }

        if (textRef.current) {
            setMessage(textRef.current.innerHTML);
        }
    };

    const onKeyDown = (event) => {
        if (event.key === "Enter" && !event.ctrlKey) {
            event.preventDefault();
            return;
        }
        /* Any Shortcut except Ctrl + V */
        const isValidShortcut = (event.ctrlKey && event.keyCode !== 86);

        const isValidKeyCode = [8, 16, 17, 37, 38, 39, 40, 46].includes(event.keyCode);

        const text = event.target.innerText;

        if (text?.length >= 200 && !isValidKeyCode && !isValidShortcut) {
            event.preventDefault();
        }
        if (textRef.current && event.key === "Enter" && event.ctrlKey) {
            sendMessage();
        }
    };

    const handlePaste = (event) => {
        event.preventDefault();
        let text = "";
        if (event.clipboardData || event.originalEvent.clipboardData) {
            text = (event.originalEvent || event).clipboardData.getData("text/plain");
        } else if (window.clipboardData) {
            text = window.clipboardData.getData("Text");
        }
        if (document.queryCommandSupported("insertText")) {
            document.execCommand("insertText", false, text);
        } else {
            document.execCommand("paste", false, text);
        }
    };

    const insertEmoji = (el) => {
        const img = document.createElement("img");
        img.src = el.icon;
        img.width = 24;
        img.setAttribute("data-code", el.code);
        setMessage(oldMessage => oldMessage + img.outerHTML);
    };

    const styledHeader = useMemo(() => {
        return <StyledChatHeader className="chat-header">
            <StyledChatTitle>{t("chat")}</StyledChatTitle>
            <StyledChatHeaderActions className="chat-header-actions">
                <div className={"chat-header__option mail-icon"} onClick={() => toggleConversations(true)}>
                    <StyledChatHeaderMailImg alt={"mail"}/>
                </div>
                <div className={"chat-header__option"} onClick={() => toggleFriends(true)}>
                    <StyledChatHeaderUsersImg alt={"users"}/>
                </div>
                <div className={"chat-header__option"} style={!rooms ? {opacity: 0.4, cursor: "not-allowed"} : {}}
                     onClick={() => {
                         if (!rooms) return;
                         setShowRooms(true)
                     }}>
                    <StyledChatHeaderLang>
                        {languages[langIndex]}
                    </StyledChatHeaderLang>
                </div>
            </StyledChatHeaderActions>
        </StyledChatHeader>;
    }, [t, langIndex, toggleConversations, toggleFriends, rooms]);

    useEffect(() => {
        if (showRain) {
            window.history.pushState({modal: true}, '');

            const handlePopState = () => {
                toggleRain(false);
            };

            window.addEventListener('popstate', handlePopState);
            return () => window.removeEventListener('popstate', handlePopState);
        }
    }, [showRain, toggleRain]);

    useEffect(() => {
        const handleDocumentClick = (event) => {
            if (chatWrapperRef.current && chatWrapperRef.current.contains(event.target)) {
                setChatIsClicked(true);
            } else {
                setChatIsClicked(false);
            }
        };

        document.addEventListener("click", handleDocumentClick);

        return () => {
            document.removeEventListener("click", handleDocumentClick);
        };
    }, []);

    return (
        <>
            <StyledLeftSidebarChatWrapper
                className={`chat-wrapper ${isNavigation ? "navigation" : ""}`}
                visible={visible}
                ref={chatWrapperRef}
            >
                <Notification
                    notification={notification}
                    setNotification={setNotification}
                />
                <section
                    className="close"
                    onClick={() => {
                        setVisible(false);
                        setMenu("");
                    }}
                ></section>
                <ChatRooms
                    active={showRooms}
                    rooms={rooms}
                    setRoom={setRoom}
                    closeRooms={() => setShowRooms(false)}
                    setLangIndex={setLangIndex}
                    joinRoom={joinRoom}
                />
                <ChatFriends active={showFriends && authenticated} closeFriends={() => setShowFriends(false)}/>
                <ChatConversations active={showConversations && authenticated}
                                   closeConversations={() => setShowConversations(false)}/>
                <StyledChatWrapper>
                    {styledHeader}
                    {loading ? <StyledChatLoader>
                            <img src={loadingGif} alt={"loading..."}/>
                        </StyledChatLoader> :
                        <>
                            <Dialog
                                visible={showRain && authenticated}
                                wrapClassName="default-modal-wrapper"
                                onClose={() => toggleRain(false)}
                                animation="zoom"
                                maskAnimation="fade"
                                title={t("letItRain")}
                                forceRender={false}
                                className="default-modal rain-modal"
                                style={{margin: "auto"}}
                            >
                                <Rain
                                    room={room}
                                    setVisible={toggleRain}
                                />
                            </Dialog>
                            {authenticated && <SendTipWindow
                                activeNickname={nickname}
                                room={room}
                            />}
                            <ChatMessages
                                messages={messages}
                                ref={messagesContainerRef}
                                room={room?.id}
                            />

                            <div>
                                <StyledChatBottomActions className="chat-bottom-actions">
                                    <StyledChatBottomInput
                                        ref={textRef}
                                        contentEditable
                                        maxlength={200}
                                        placeholder={t("chatPlaceholder")}
                                        onInput={onInput}
                                        onKeyDown={onKeyDown}
                                        onPaste={handlePaste}
                                        onFocus={() => setIsFocused(true)}
                                        onBlur={() => setIsFocused(false)}
                                        onChange={(e) => {
                                            if (e.currentTarget) {
                                                setMessage(e.currentTarget.innerHTML);
                                            }
                                        }}
                                    >
                                    </StyledChatBottomInput>

                                    <StyledChatBottomButtons>
                                        <StyledChatBottomImage
                                            src={infoImg}
                                            onClick={() => setShowInfo(!showInfo)}
                                            width={20}
                                            height={20}
                                        />
                                        <StyledChatBottomImage
                                            src={rainImg}
                                            onClick={() => toggleRain(true)}
                                            width={20}
                                            height={20}
                                        />
                                        <StyledChatBottomImage
                                            src={smileImg}
                                            onClick={() => setShowEmoji(!showEmoji)}
                                            width={20}
                                            height={20}
                                        />
                                    </StyledChatBottomButtons>
                                </StyledChatBottomActions>

                                <StyledChatEmojiBlock
                                    active={showEmoji}
                                    className="chat-emoji-block"
                                >
                                    {chatEmoji?.map((el) => (
                                        <StyledChatEmojiIcon
                                            key={el.code}
                                            src={el.icon}
                                            // src={`${process.env.PUBLIC_URL || ""}${el}`}
                                            onClick={() => insertEmoji(el)}
                                        />
                                    ))}
                                </StyledChatEmojiBlock>

                                <StyledChatInfoBlock
                                    active={showInfo}
                                    className="chat-info-block"
                                >
                                    <StyledChatInfoRow>
                                        <StyledChatBottomInfoText bold={true}>
                                            {t("sendMessage")}:
                                        </StyledChatBottomInfoText>
                                        <StyledChatInfoRowRight>
                                            <StyledChatInfoColouredBox capitalize>
                                                Ctrl+Enter
                                            </StyledChatInfoColouredBox>
                                        </StyledChatInfoRowRight>
                                    </StyledChatInfoRow>

                                    <StyledChatInfoRow>
                                        <StyledChatBottomInfoText bold={true}>
                                            {t("addUserLink")}:
                                        </StyledChatBottomInfoText>
                                        <StyledChatInfoRowRight>
                                            <StyledChatInfoColouredBox>
                                                {t("user")}:{t("userNameBrackets")}
                                            </StyledChatInfoColouredBox>
                                            <StyledChatBetExample>
                                                {t("example")} - {t("user")}:
                                                <StyledChatText>p1AYER</StyledChatText>
                                            </StyledChatBetExample>
                                        </StyledChatInfoRowRight>
                                    </StyledChatInfoRow>

                                    <StyledChatInfoRow>
                                        <StyledChatBottomInfoText bold={true}>
                                            {t("linkToAddBet")}:
                                        </StyledChatBottomInfoText>
                                        <StyledChatInfoRowRight>
                                            <StyledChatInfoColouredBox>
                                                {t("bet")}:(id)
                                            </StyledChatInfoColouredBox>
                                            <StyledChatBetExample>
                                                {t("example")} - {t("bet")}:123456
                                            </StyledChatBetExample>
                                        </StyledChatInfoRowRight>
                                    </StyledChatInfoRow>
                                </StyledChatInfoBlock>

                                <StyledChatBottomInfo className="chat-bottom-info">
                                    <StyledChatBottomInfoText>
                                        {t("onlineUsersCount", {n: onlineCount ?? 0})}
                                    </StyledChatBottomInfoText>
                                    <StyledButton
                                        className={"chat_send-btn"}
                                        color="neutral"
                                        onClick={sendMessage}
                                        disabled={!authenticated || sendMessageLoading || userIsBanned}
                                    >
                                        {t("send")}
                                    </StyledButton>
                                </StyledChatBottomInfo>
                            </div>
                        </>}
                </StyledChatWrapper>

            </StyledLeftSidebarChatWrapper>
            <ChatCaptchaModal visible={showCaptcha} setVisible={setShowCaptcha} socketRef={socketRef}
                              setCaptchaSuccess={setCaptchaSuccess}/>
        </>
    );
};

export default LeftSidebarChat;