import AlertMessage from "../../alert/Alert";
import React, {useEffect, useRef, useState} from "react";
import ReCaptcha from "../../reCaptcha/ReCaptcha";
import {
    StyledAlertAmountUser,
    StyledHelpElementAlertAmountUser,
    StyledLegendBonusWrapper,
    StyledTimer,
    StyledDayItem,
    StyledDayItemAmount,
    StyledDayItemHeader,
    StyledDaysBlock, Container
} from "./styledLegendFaucet";
import axios from "axios";
import userAuthenticationConfig from "../../../../utils/userAuthenticationConfig";
import {responseStatus} from "../../../../utils/consts";
import {closableNotification} from "../../notification/ClosableNotification";
import loadingGif from "../../../../assets/images/loading.gif";
import {StyledSpan} from "../../../styles/styledTypography";
import Big from "big.js";
import {StyledButton} from "../../../styles/styledButton";
import {useTranslation} from "react-i18next";
import {useBetween} from "use-between";
import BalanceStates from "../../../games/BalanceStates";
import LoadButton from "../../spinner/ButtonSpinner";
import {StyledModalLoader} from "../Faucets/styledFaucetsModal";

function LegendFaucet({setVisible, visible, t, getFaucetsStatusReq, bonusAvailable, remainingTime}) {
    const {t: errorsT} = useTranslation("errors");

    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingBtn, setIsLoadingBtn] = useState(false);
    const [bonusInfoData, setBonusInfoData] = useState(null);

    const {setBalance, activeCurrency, setPaymentMethods} = useBetween(BalanceStates);

    const recaptchaRef = useRef(null);

    useEffect(() => {
        if (!visible) return;
        getLegendBonusInfo();
    }, [visible])

    const formatSatoshi = (amount) => {
        let value = amount;
        if (amount.includes('.')) {
            value = value.replace(/0+$/, '').replace(/\.$/, '');
        }
        return value;
    }

    const getLegendBonus = () => {
        const currentBonus = bonusInfoData?.bonus_by_days?.find((bonusDay) => bonusDay.day === bonusInfoData?.bonus_day);
        const bonusPercentage = new Big(currentBonus.amount).times(new Big(bonusInfoData?.claim_bonus).div(100)).toString();
        return formatSatoshi(bonusPercentage)
    }

    const getLegendBonusInfo = () => {
        setIsLoading(true);
        axios.get(`${process.env.REACT_APP_MODERATOR_LINK}/legend-bonus/get-bonus-info`, userAuthenticationConfig()).then(response => {
            if (response.status === responseStatus.HTTP_OK) {
                setBonusInfoData(response.data);
                setIsLoading(false);
            }
        }).catch(error => {
            closableNotification(error.response.data.message, "error");
            setVisible(false);
        });
    };

    const claimLegendBonus = () => {
        if (!recaptchaRef.current) return;
        if (!recaptchaRef.current.getValue()) return closableNotification(errorsT("Failed authentication, suspicious activity"), "error");
        setIsLoadingBtn(true);
        recaptchaRef.current.reset();
        axios.post(`${process.env.REACT_APP_MODERATOR_LINK}/legend-bonus/claim-bonus`, {}, userAuthenticationConfig()).then(response => {
            if (activeCurrency === bonusInfoData?.bonus_currency?.asset) setBalance(prevState => {
                return (new Big(prevState ?? 0).plus(new Big(response?.data?.claimed_amount))).toFixed(8);
            })
            setPaymentMethods(prevState =>
                prevState?.map(paymentMethod =>
                    paymentMethod?.currency?.asset === bonusInfoData?.bonus_currency?.asset
                        ? {
                            ...paymentMethod,
                            balances: {
                                ...paymentMethod.balances,
                                amount: (new Big(paymentMethod.balances.amount ?? 0).plus(new Big(response?.data?.claimed_amount))).toFixed(8)
                            }
                        }
                        : paymentMethod)
            );
            closableNotification(t("tokenReceived"), "success");
            getFaucetsStatusReq();
            setIsLoadingBtn(false);
            setVisible(false);
        }).catch(error => {
            closableNotification(error?.response?.data?.message, "error");
            setVisible(false);
            recaptchaRef.current.reset();
            setIsLoadingBtn(false);
        });
    };

    const formatTime = (seconds) => {
        const hrs = Math.floor(seconds / 3600);
        const mins = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${hrs > 0 ? String(hrs).padStart(2, "0") + ":" : ""}${String(mins).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
    };

    return (
        <Container style={{display: visible ? "block" : "none"}}>
            {isLoading ? <StyledModalLoader>
                    <img src={loadingGif} alt={"loading..."}/>
                </StyledModalLoader> :
                <StyledLegendBonusWrapper>
                    <StyledDaysBlock>
                        {bonusInfoData?.bonus_by_days?.map((dayData) => {
                            const isDayPassed = dayData.day <= bonusInfoData?.bonus_day;
                            const currentDayStyle = dayData.day === bonusInfoData?.bonus_day;
                            const prevDayStyle = dayData.day < bonusInfoData?.bonus_day;

                            const examinationProgressPercentage = dayData.day === bonusInfoData?.bonus_day ? bonusInfoData?.bonus_progress : 100;

                            return (
                                <StyledDayItem key={dayData.day} hasDay={isDayPassed}>
                                    <StyledDayItemHeader fillPercentage={examinationProgressPercentage}
                                                         prevDayStyle={prevDayStyle} currentDayStyle={currentDayStyle}>
                                        <StyledSpan>
                                            {t("day", {dayNumber: dayData?.day})}
                                        </StyledSpan>
                                    </StyledDayItemHeader>
                                    <StyledDayItemAmount hasDay={isDayPassed}>
                                        <b>
                                            {formatSatoshi(dayData?.amount)}
                                        </b>
                                        {bonusInfoData.bonus_currency.asset}
                                    </StyledDayItemAmount>
                                </StyledDayItem>
                            );
                        })}
                    </StyledDaysBlock>
                    <StyledAlertAmountUser>
                        {t("yourBonus")}: {getLegendBonus() + " " + bonusInfoData.bonus_currency.asset}
                        <StyledHelpElementAlertAmountUser>
                            ?
                            <div className="help-el__text">
                                {t("bonusLevelInfo")}
                            </div>
                        </StyledHelpElementAlertAmountUser>
                    </StyledAlertAmountUser>
                    <ReCaptcha recaptchaRef={recaptchaRef}/>
                    <AlertMessage mb={15}>
                        {t("moderatorBonusAlertInfo", {
                            currency: bonusInfoData?.bonus_currency?.name,
                            min_amount: formatSatoshi(bonusInfoData?.bonus_by_days?.[0].amount)
                        })}
                    </AlertMessage>
                    {!bonusAvailable ? (
                        <StyledTimer>
                            {formatTime(remainingTime)}
                        </StyledTimer>
                    ) : (isLoadingBtn ? <LoadButton
                            text={t("getToken")}
                            color="neutral"
                            type="submit"
                            style={{width: '100%'}}
                        /> : <StyledButton
                            onClick={claimLegendBonus}
                            color="neutral"
                            type="submit"
                            width="100"
                            disabled={!bonusAvailable}
                        >
                            {t("getToken")}
                        </StyledButton>
                    )}
                </StyledLegendBonusWrapper>
            }
        </Container>
    );
}

export default LegendFaucet;