import axios from "axios";

import {useBetween} from "use-between";
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {generatePath} from "../../../../utils/getLanguage";

import {
    StyledBanListTable, StyledBanListTableBody,
    StyledBanListTableHead,
    StyledBanListTableItem,
    StyledBanListTableRow, StyledWrapperTable
} from "./styledBanListModal";
import {StyledModalLoader} from "../../../elements/payment/styledPaymentInvoice";
import {StyledButton} from "../../../styles/styledButton";
import {closableNotification} from "../../../elements/notification/ClosableNotification";

import UnbanUserModal from "../unbanUserModal/UnbanUserModal";
import Dialog from "rc-dialog";
import {NavLink} from "react-router-dom";

import ProfileActionsStates from "../../ProfileActionsStates";
import {AppContext} from "../../../../App";

import userAuthenticationConfig from "../../../../utils/userAuthenticationConfig";
import {responseStatus} from "../../../../utils/consts";

import loadingGif from "../../../../assets/images/loading.gif";
import userIcon from "../../../../assets/images/user_icon_dark.svg";
import {EmptyTable} from "../userBanListModal/styledUserBanListModal";
import {decodeMessage} from "../../../elements/leftSidebar/Chat/utils";
import UserTag, {tagRegex as userTagRegex} from "../../../elements/tags/UserTag";
import {renderToStaticMarkup} from "react-dom/server";
import BetTag, {tagRegex as betTagRegex} from "../../../elements/tags/BetTag";
import CurrencyTag, {tagRegex as currencyTagRegex} from "../../../elements/tags/CurrencyTag";
import EmojiTag, {tagRegex as emojiTagRegex} from "../../../elements/tags/EmojiTag";

function BanListModal() {
    const {t} = useTranslation("siteOptions");

    const [banListData, setBanListData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [currentTime, setCurrentTime] = useState(Math.floor(Date.now() / 1000));

    const {
        setVisibleBanListModal,
        setVisibleUnbanUserModal,
        visibleBanListModal,
        currentBanData,
        setCurrentBanData
    } = useBetween(ProfileActionsStates);

    const {authenticated} = useContext(AppContext);

    useEffect(() => {
        if (!visibleBanListModal) return;
        getBanListData();
    }, [visibleBanListModal]);

    useEffect(() => {
        if (!visibleBanListModal) return;

        const timer = setInterval(() => {
            setCurrentTime(Math.floor(Date.now() / 1000));
        }, 60000);

        return () => clearInterval(timer);
    }, [visibleBanListModal]);

    const getBanListData = () => {
        setIsLoading(true);
        axios.get(`${process.env.REACT_APP_MODERATOR_LINK}/bans/get-ban-list`, userAuthenticationConfig()).then(response => {
            if (response.status === responseStatus.HTTP_OK) {
                setBanListData(response.data);
                setIsLoading(false);
            }
        }).catch(error => {
            closableNotification(error.response.data.message, "error");
            setVisibleBanListModal(false);
        });
    };

    function formatMinutes(minutes) {
        const units = [
            {label: t("botMessageWeeks"), value: 60 * 24 * 7},
            {label: t("botMessageDays"), value: 60 * 24},
            {label: t("botMessageHours"), value: 60},
            {label: t("botMessageMinutes"), value: 1}
        ];

        let result = [];

        for (const unit of units) {
            if (minutes >= unit.value) {
                const amount = Math.floor(minutes / unit.value);
                minutes %= unit.value;
                result.push(`${amount}${unit.label}`);
            }
        }

        return result.join(' ') || '0m';
    }

    function calculateRemainingTime(banned_at, ban_time) {
        const currentTime = Math.floor(Date.now() / 1000);
        const banEndTimestamp = banned_at + (ban_time * 60);
        const remainingSeconds = banEndTimestamp - currentTime;

        if (remainingSeconds <= 0) {
            return t("expired");
        }

        return formatMinutes(Math.ceil(remainingSeconds / 60));
    }

    const openUnbanUserModal = (banData) => {
        setCurrentBanData(banData);
        setVisibleUnbanUserModal(true);
        setVisibleBanListModal(false);
    }

    const processBeforeOutput = (value, user) => {
        if (typeof value === 'string') {
            value = value.replace(userTagRegex, (substr, username) => {
                const isCurrentUser = user === username;
                const hintData = !isCurrentUser ? {
                    "data-custom": true,
                    "data-custom-at": "right",
                    "data-id": window.crypto.getRandomValues(new Uint8Array(10)).join(''),
                    "data-nickname": username,
                } : {};
                return renderToStaticMarkup(<UserTag username={username} isCurrentUser={isCurrentUser}
                                                     hintData={hintData}/>);
            });
            value = value.replace(betTagRegex, (substr, id) => renderToStaticMarkup(<BetTag id={id}/>));
            value = value.replace(currencyTagRegex, (substr, coinName) => renderToStaticMarkup(
                <CurrencyTag coinSymbol={coinName}/>));
            value = value.replace(emojiTagRegex, (substr, emojiCode) => renderToStaticMarkup(<EmojiTag
                emojiCode={emojiCode}/>));
        }
        return decodeMessage(value);
    }

    return (
        <>
            <UnbanUserModal currentBanData={currentBanData}/>
            <Dialog
                visible={authenticated && visibleBanListModal}
                wrapClassName="default-modal-wrapper"
                onClose={() => setVisibleBanListModal(false)}
                animation="zoom"
                maskAnimation="fade"
                title={t("blocked")}
                forceRender={false}
                className="default-modal ban-list-modal"
                style={{margin: "auto"}}
            >
                {isLoading || !banListData ?
                    <StyledModalLoader style={{height: "200px", minHeight: "auto", margin: "auto"}}><img
                        src={loadingGif} alt={"loading..."}/></StyledModalLoader> :
                    banListData?.length === 0 ? <EmptyTable>{t("noBans")}</EmptyTable> :
                        <StyledWrapperTable>
                            <StyledBanListTable>
                                <StyledBanListTableHead>
                                    <StyledBanListTableRow>
                                        <StyledBanListTableItem>{t("user")}</StyledBanListTableItem>
                                        <StyledBanListTableItem>{t("botMessageReason")}</StyledBanListTableItem>
                                        <StyledBanListTableItem>{t("message")}</StyledBanListTableItem>
                                        <StyledBanListTableItem>{t("time")}</StyledBanListTableItem>
                                        <StyledBanListTableItem>{t("timeLeft")}</StyledBanListTableItem>
                                        <StyledBanListTableItem>{t("action")}</StyledBanListTableItem>
                                    </StyledBanListTableRow>
                                </StyledBanListTableHead>
                                <StyledBanListTableBody>
                                    {banListData?.map((banData, key) => (
                                        <StyledBanListTableRow key={key}>
                                            <StyledBanListTableItem>
                                                <NavLink
                                                    to={generatePath(`/account/${banData?.user}`)}
                                                    onClick={() => setVisibleBanListModal(false)}
                                                    className="wrapper-link"
                                                >
                                                    <img
                                                        src={userIcon}
                                                        alt="user"
                                                    />
                                                    {banData?.user}
                                                </NavLink>
                                            </StyledBanListTableItem>
                                            <StyledBanListTableItem
                                                style={{maxWidth: "140px"}}>{banData?.cause}</StyledBanListTableItem>
                                            <StyledBanListTableItem
                                                dangerouslySetInnerHTML={{__html: processBeforeOutput(banData?.message, banData?.user)}}
                                                style={{maxWidth: "140px"}}/>
                                            <StyledBanListTableItem>{formatMinutes(banData?.ban_time)}</StyledBanListTableItem>
                                            <StyledBanListTableItem>{calculateRemainingTime(banData?.banned_at, banData?.ban_time)}</StyledBanListTableItem>
                                            <StyledBanListTableItem>
                                                <StyledButton onClick={() => openUnbanUserModal(banData)}>
                                                    <i className="icon icon-error">&#xe905;</i>
                                                </StyledButton>
                                            </StyledBanListTableItem>
                                        </StyledBanListTableRow>
                                    ))}
                                </StyledBanListTableBody>
                            </StyledBanListTable>
                        </StyledWrapperTable>
                }
            </Dialog>
        </>
    )
}

export default BanListModal;