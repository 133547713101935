import { useState, useCallback, useEffect, useMemo } from "react";
import * as SessStorage from "../../utils/sessionStorage";

const defaultState = {
  currency: null,
  sumOfBet: 0,
  profit: 0,
  bet: 0,
  countOfBet: 0,
  countOfWin: 0,
  countOfLose: 0,
  percentOfLuck: 0,
  // countOfMessages: 0,
  maxBet: 0,
  maxProfit: 0
};

// const myStorage = sessionStorage || localStorage;

const CurrentStatisticsStates = () => {
  const [currency, setCurrency] = useState(null);
  const [statistics, setStatistics] = useState(defaultState);
  const [countOfMessages, setCountOfMessages] = useState(0);

  const updateStatistics = useCallback((data = null, game = null) => {
    setStatistics((oldStatistics) => {
      if (oldStatistics?.currency === currency) {
        // console.log('updateStatistics,oldStatistics:', JSON.parse(JSON.stringify(oldStatistics)));
        // console.log('updateStatistics,data:', data);
        let newProfit;
        let toFixed = (n, fixed) => ~~(Math.pow(10, fixed) * n) / Math.pow(10, fixed);
        oldStatistics.sumOfBet = (oldStatistics.sumOfBet * 10000000 + Math.abs(data.bet) * 10000000) / 10000000;
        oldStatistics.bet = toFixed(data.bet, 10);

        const isLoss = Number(data.lose) > 0;

        // console.log('updateStatistics,isLoss:', isLoss);
        if (!isLoss) {
          newProfit = +oldStatistics.profit + +data.win;
          // console.log('updateStatistics,newProfit:', newProfit);
          oldStatistics.countOfWin = oldStatistics.countOfWin + 1;
        } else {
          newProfit = +oldStatistics.profit - +data.bet;
          // console.log('updateStatistics,newProfit:', newProfit);
          oldStatistics.countOfLose = oldStatistics.countOfLose + 1;
        }

        oldStatistics.countOfBet = oldStatistics.countOfLose + oldStatistics.countOfWin;
        oldStatistics.profit = newProfit; // toFixed(newProfit, 10);
        // console.log('updateStatistics,oldStatistics.profit:', oldStatistics.profit);
        oldStatistics.percentOfLuck = (oldStatistics.profit + oldStatistics.sumOfBet) / oldStatistics.sumOfBet * 100;
        /*toFixed(
          (oldStatistics.profit + oldStatistics.sumOfBet) / oldStatistics.sumOfBet * 100,
          2,
        );*/
        // oldStatistics.countOfMessages = data.countOfMessages;

        if (!oldStatistics.maxBet) {
          oldStatistics.maxBet = 0;
        }

        if (oldStatistics.maxBet < data.bet) {
          oldStatistics.maxBet = data.bet;
        }

        if (oldStatistics.maxProfit < data.win) {
          oldStatistics.maxProfit = data.win;
        }
        return { ...oldStatistics };
      }
      return oldStatistics;
    });
  }, [currency]);

  const incrementMessages = useCallback(() => {
    setCountOfMessages((prevValue) => prevValue + 1);
  }, []);

  useEffect(() => {
    if (currency) {
      const starageKey = `currentStatistics_${currency}`;
      let oldStatistics = SessStorage.getItem(starageKey);
      if (!oldStatistics || oldStatistics === "undefined") {
        oldStatistics = { ...defaultState, currency };
      }
      setStatistics(oldStatistics);
    }
  }, [currency]);

  useEffect(() => {
    const starageKey = `currentStatistics_countOfMessages`;
    let oldData = SessStorage.getItem(starageKey);
    if (!oldData || oldData === "undefined") {
      oldData = { value: 0 };
    }
    setCountOfMessages(oldData.value);
  }, []);

  useEffect(() => {
    const starageKey = `currentStatistics_${statistics?.currency}`;
    SessStorage.setItem(starageKey, statistics);
  }, [statistics]);

  useEffect(() => {
    const starageKey = `currentStatistics_countOfMessages`;
    SessStorage.setItem(starageKey, { value: countOfMessages });
  }, [countOfMessages]);

  const clearCurrentStatistics = useCallback(() => {
    setStatistics({ ...defaultState, currency });
    setCountOfMessages(0);
  }, [currency]);

  const mergedStatistics = useMemo(
    () => ({ ...statistics, countOfMessages }),
    [countOfMessages, statistics],
  );

  return {
    setCurrency,
    setStatistics,
    statistics: mergedStatistics,
    updateStatistics,
    incrementMessages,
    clearCurrentStatistics,
  }
};

export default CurrentStatisticsStates;
