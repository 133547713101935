import { useState } from "react";

export const VisibleLeftSidebarStates = () => {
  const [visible, setVisible] = useState(false);
  const [menu, setMenu] = useState("");
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState(null);
  const [focus, setFocus] = useState(false);
  const [tokenBonusVisible, setTokenBonusVisible] = useState(false);
  const [gameBonusVisible, setGameBonusVisible] = useState(false);
  const [rulesBonusVisible, setRulesBonusVisible] = useState(false);
  const [exchangeVisible, setExchangeVisible] = useState(false);
  const [faucetsVisible, setFaucetsVisible] = useState(false);
  const [faucetsStatusData, setFaucetsStatusData] = useState([]);
  const [chatIsClicked, setChatIsClicked] = useState(false);

  return {
    visible,
    setVisible,
    menu,
    setMenu,
    message,
    setMessage,
    messages,
    setMessages,
    focus,
    setFocus,
    tokenBonusVisible,
    setTokenBonusVisible,
    gameBonusVisible,
    setGameBonusVisible,
    rulesBonusVisible,
    setRulesBonusVisible,
    faucetsVisible,
    setFaucetsVisible,
    exchangeVisible,
    setExchangeVisible,
    faucetsStatusData,
    setFaucetsStatusData,
    chatIsClicked,
    setChatIsClicked
  };
}