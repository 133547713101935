import styled from "styled-components";
import { device } from "../../../styles/responsive/responsiveUtils";

export const StyledLeftSidebarChatWrapper = styled.div`
  height: 100%;

  &.navigation {
    display: none;
    @media screen and ${device('desktop')} {
      display: block;
    }
  }

  .close {
    display: none;
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .close:before, .close:after {
    content: "";
    position: relative;
    width: 24px;
    height: 2px;
    background: gray;
    top: 50%;
  }

  .close:before {
    transform: rotate(45deg);
  }

  .close:after {
    transform: rotate(-45deg);
  }

  @media screen and ${device('desktop')} {
    position: fixed;
      top: unset;
      bottom: 0;
      right: 0;
      left: 0;
      height: 100%;
      max-height: 0;
    ${({ visible }) => visible && `
        max-height: 100%;
        position: fixed;
        transition: all .2s;
      `};
    z-index: 30;
    ${({ theme }) => theme.leftSidebarContainer};

    .close {
      display: block;

      &:before, &:after {
        position: absolute;
      }
    }

    .close:before, .close:after {
      position: absolute;
    }
  }
`;

export const StyledChatNoUserOrRoomWrapper = styled(StyledLeftSidebarChatWrapper)`
  padding: 20px;

  &.navigation {
    display: none;
    @media screen and ${device('desktop')} {
      display: block;
    }
  }
  
  h3 {
    margin-bottom: 20px;
    color: ${({theme}) => theme.subTitleColor};
    font-size: 24px;
    font-weight: 400;
  }

  @media screen and ${device('desktop')} {
    transition: all 0.5s ease;
    position: fixed;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: -70%;
    width: 0;
    ${({visible}) => visible && `
        width: 100%;
        right: auto;
        left: 0;
      `};
    z-index: 30;
    ${({ theme }) => theme.leftSidebarContainer};
  }
`;

export const StyledChatHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-width: 0 0 1px 0;
  border-style: solid;
  ${({theme}) => theme.leftSidebarChat.header}
  min-height: 74px;
  height: 74px;
  
  @media screen and ${device('desktop')} {
    min-height: 65px;
    height: 65px;
  }
`;

export const StyledChatTitle = styled.p`
  font-size: 28px;
  color: ${({theme}) => theme.text};
  line-height: 33px;
  font-weight: 100;
  padding: 20px 0 20px 20px;
    
  @media screen and (max-width: 335px) {
      font-size: 24px;
  }
`;

export const StyledChatHeaderLang = styled.div`
  ${({theme}) => theme.leftSidebarChat.lang}
  font-weight: 500;
  width: 28px;
  height: 17px;
  text-align: center;
  font-size: 14px;
  line-height: 15px;
  border-radius: 2px;
  cursor: pointer;
  text-transform: uppercase;
`;

export const StyledChatHeaderActions = styled.div`
  display: flex;
  height: 100%;
  padding-right: 10px;
  
  @media screen and ${device('desktop')} {
    display: flex;
    justify-content: end;
    margin-right: 50px;
  }
  
  & > .chat-header__option {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 53px;
    border-width: 0 0 1px 0;
    border-style: solid;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    padding: 26px 15px 23px;
    opacity: 0.6;
    cursor: pointer;

    ${({theme}) => theme.leftSidebarChat.headerActionItem}
    
    &:hover {
      border-bottom: 1px #058bd8 solid;
      opacity: 1;
    }
    @media screen and (max-width: 335px) {
        padding: 26px 10px 23px;
    }      
  }
`;

export const StyledChatHeaderMailImg = styled.img.attrs(props => ({
  src: props.theme.leftSidebarChat.headerMailImg
}))`
  height: 16px !important;
`;

export const StyledChatHeaderUsersImg = styled.img.attrs(props => ({
  src: props.theme.leftSidebarChat.headerUsersImg
}))`
  height: 19px !important;
`;

export const StyledChatBottomActions = styled.div`
  min-height: 45px;
  max-height: 500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  background-color: ${({theme}) => theme.colorsChat.backgroundInputText};
  border-top: 1px solid ${({theme}) => theme.colorsChat.borderColor};
  border-bottom: 1px solid ${({theme}) => theme.colorsChat.borderColor};
`;

export const StyledChatBottomInput = styled.div`
  display: inline-block;
  width: calc(100% - 115px);
  max-height: 500px;
  overflow-y: auto;
  font-size: 15px;
  cursor: text;
  padding: 10px 0;
  color: ${({theme}) => theme.colorsChat.inputChatText};
  &::-webkit-scrollbar {
    display: none;
  }
  &:focus {
    outline: none;
  }
  &[placeholder]:empty::before {
    content: attr(placeholder);
    font-size: 14px;
    color: ${({theme}) => theme.colorsChat.placeholderColor};
  }
  img {
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center;
    vertical-align: middle;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
  }
`;

export const StyledChatBottomButtons = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledChatBottomImage = styled.img`
  margin-right: 8px;
  opacity: 0.65;
  cursor: pointer;
  transition: all 0.3s ease-out;
  &:hover {
    opacity: 1;
  }
  &:last-child {
    margin-right: 0;
  }
`;

export const StyledChatBottomInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10px 15px 15px;
  font-size: 14px;
  max-height: 79px;

  @media screen and ${device('desktop')} {
    padding: 10px 20px;
  }
  
  & > .chat_send-btn {
    margin: 0;
    width: 130px;
  }
`;

export const StyledChatEmojiBlock = styled.div`
  display: flex;
  max-height: ${(props) => (props.active ? "90px" : "0")};
  overflow: ${(props) => (props.active ? "visible" : "hidden")};
  padding: ${(props) => (props.active ? "15px 10px 3px 15px" : "0")};
  transition: max-height 300ms ease;
  align-items: center;
  background-color:${({theme}) => theme.colorsChat.backgroundEmoji};
  flex-wrap: wrap;
  overflow-y: auto;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    background-color: transparent;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: ${({theme}) => theme.colorsChat.backgroundColor};
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    border: 1px solid transparent;
    background-color: ${({theme}) => theme.colorsChat.colorTime};
    border-radius: 6px;
  }
`;

export const StyledChatEmojiIcon = styled.img`
  display: block;
  margin: 0 5px 12px 0;
  cursor: pointer;
  width: 24px;
`;

export const StyledChatText = styled.span`
  text-transform: initial;
`;

export const StyledChatBottomInfoText = styled.p`
  width: 130px;
  color: ${({theme}) => theme.mainTextColor};
  ${({bold}) => bold && "font-weight: 500;"}
`;

export const StyledChatInfoBlock = styled.div`
  background-color: ${({theme}) => theme.colorsChat.backgroundInfo};
  padding: ${(props) => (props.active ? "10px 0px 10px" : "0")};
  display: flex;
  flex-direction: column;
  max-height: ${(props) => (props.active ? "375px" : "0")};
  overflow: ${(props) => (props.active ? "visible" : "hidden")};
  transition: max-height 300ms ease;
  border-left: 5px solid ${({theme}) => theme.colorsChat.bgColorBotTitle};
  border-radius: 3px;
  overflow: hidden;
  color: #555555;
  font-size: 14px;
`;

export const StyledChatInfoRow = styled.div`
  display: flex;
  align-items: center;
  padding: 7px 20px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledChatTextExample = styled(StyledChatBottomInfoText)`
  padding: 5px 0 0 8px;
  font-size: 13px;
  color: #5f5f5f;
  text-transform: lowercase;
`;

export const StyledChatInfoColouredBox = styled(StyledChatHeaderLang)`
  pointer-events: none;
  text-transform: ${(props) => (props.capitalize ? "capitalize" : "lowercase")};
  width: fit-content;
  background-color: ${({theme}) => theme.colorsChat.buttonBackground};
  color: #ffffff;
  padding: 5px 7px;
  height: auto;
  &:hover {
    pointer-events: none;
  }
`;

export const StyledChatInfoRowRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 55%;
`;

export const StyledChatBetExample = styled(StyledChatTextExample)`
  margin: 0;
  width: 100%;
`;

export const StyledChatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
`;

export const StyledChatLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
