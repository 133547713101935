import React, {useState} from "react";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {StyledHoverNavigation, StyledMenuList, StyledNavigationWrapper} from "../navigation/styledNavigation";
import {generatePath} from "../../../utils/getLanguage";
import {useBetween} from "use-between";
import BurgerStates from "../mobileNavigation/BurgerStates";

const FooterNavigation = () => {
    const [hoveLineWrapperStyle, setHoveLineWrapperStyle] = useState({
        visible: "hidden",
        opacity: 0,
        left: 20
    });
    const [hoveLineStyle, setHoveLineStyle] = useState({
        visible: "hidden",
        opacity: 0,
        width: 10
    });
    const {t} = useTranslation("footer");

    const {
        handleClearAll
    } = useBetween(BurgerStates);

    const hoverNavigation = (e, hover) => {
        if (hover) {
            setHoveLineWrapperStyle((prevState) => ({
                ...prevState,
                visible: "visible",
                opacity: 1,
                left: e.target.offsetLeft + 18
            }));
            setHoveLineStyle((prevState) => ({
                ...prevState,
                visible: "visible",
                opacity: 1,
                width: e.target.offsetWidth - 18 - 18
            }));
        } else {
            setHoveLineWrapperStyle((prevState) => ({
                ...prevState,
                visible: "hidden",
                opacity: 0,
                left: e.target.offsetLeft + 18
            }));
            setHoveLineStyle((prevState) => ({
                ...prevState,
                visible: "hidden",
                opacity: 0,
                width: e.target.offsetWidth - 18 - 18
            }));
        }
    };

    return (
        <StyledNavigationWrapper>
            <StyledMenuList>
                <li
                    className="menu-item"
                    onMouseOver={(e) => hoverNavigation(e, true)}
                    onMouseOut={(e) => hoverNavigation(e, false)}
                >
                    <NavLink
                        to={generatePath("/support")}
                        onClick={handleClearAll}
                        className="menu-item_link"
                        activeClassName="menu-item_link-active"
                    >
                        {t("support")}
                    </NavLink>
                </li>
                <li
                    className="menu-item"
                    onMouseOver={(e) => hoverNavigation(e, true)}
                    onMouseOut={(e) => hoverNavigation(e, false)}
                >
                    <NavLink
                        to={generatePath("/politic")}
                        onClick={handleClearAll}
                        className="menu-item_link"
                        activeClassName="menu-item_link-active"
                    >
                        {t("politic")}
                    </NavLink>
                </li>
                <li
                    className="menu-item"
                    onMouseOver={(e) => hoverNavigation(e, true)}
                    onMouseOut={(e) => hoverNavigation(e, false)}
                >
                    <NavLink
                        to={generatePath("/wallets")}
                        onClick={handleClearAll}
                        className="menu-item_link"
                        activeClassName="menu-item_link-active"
                    >
                        {t("wallets")}
                    </NavLink>
                </li>
                <li
                    className="menu-item"
                    onMouseOver={(e) => hoverNavigation(e, true)}
                    onMouseOut={(e) => hoverNavigation(e, false)}
                >
                    <NavLink
                        to={generatePath("/faq")}
                        onClick={handleClearAll}
                        className="menu-item_link"
                        activeClassName="menu-item_link-active"
                    >
                        {t("faq")}
                    </NavLink>
                </li>
                <li
                    className="menu-item"
                    onMouseOver={(e) => hoverNavigation(e, true)}
                    onMouseOut={(e) => hoverNavigation(e, false)}
                >
                    <a href={'https://t.me/luckygames_official'} target={'_blank'}
                       className="menu-item_link">Telegram</a>
                </li>
            </StyledMenuList>
            <StyledHoverNavigation style={hoveLineWrapperStyle}>
                <div style={hoveLineStyle}/>
            </StyledHoverNavigation>
        </StyledNavigationWrapper>
    );
};

export default FooterNavigation;