import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useBetween } from "use-between";
import { NavLink, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ProfileActionsStates from "./ProfileActionsStates";
import { VisibleLeftSidebarStates } from "../elements/leftSidebar/VisibleLeftSidebarStates";
import { generatePath } from "../../utils/getLanguage";
import {LeftSidebarMenu, privileges, responseStatus, roles} from "../../utils/consts";
import { StyledWrapper } from "./styledProfileActions";
import { AppContext } from "../../App";
import { closableNotification } from "../elements/notification/ClosableNotification";
import BurgerStates from "../elements/mobileNavigation/BurgerStates";
import axios from "axios";
import userAuthenticationConfig from "../../utils/userAuthenticationConfig";

const ProfileActionsWindow = ({ placement, keys, nickname, parentRef = null, button = null }) => {
  const history = useHistory();

  const {
    visibleProfileAction,
    setVisibleProfileAction,
    place,
    setVisibleTipWindow,
    setNickname,
    setVisibleUserInfoModal,
    messageData,
    setMessageData,
    setVisibleModeratorsInfoModal,
    setVisibleBanListModal,
    setVisibleBanUserModal,
    setVisibleUserBanListModal,
    setVisiblePrivilegesVipModal,
    setVisiblePrivilegesLegModal
  } = useBetween(ProfileActionsStates);
  const { setGameBonusVisible } = useBetween(VisibleLeftSidebarStates);

  const [position, setPosition] = useState({ x: 0, y: -50 });
  const [arrowPosition, setArrowPosition] = useState({ y: 70 });
  const [isInitialRender, setIsInitialRender] = useState(true);

  const { t } = useTranslation('siteOptions');
  const { t: errorsT } = useTranslation('errors');

  const { authenticated, user } = useContext(AppContext);

  const profileActionsRef = useRef(null);
  const calcPosTimeoutRef = useRef(null);

  const calcPos = useCallback(() => {
    if (button && parentRef?.current && profileActionsRef?.current) {
      const { right, bottom, top, height } = button.getBoundingClientRect();
      const { top: parentTop, bottom: parentBottom } = parentRef.current.getBoundingClientRect();

      if (top <= parentTop || bottom >= parentBottom) {
        return;
      }

      const pos = {};
      const maxW = parentRef.current.offsetWidth - profileActionsRef.current.offsetWidth - 40;
      if (right > maxW) {
        pos.x = maxW - right;
      } else {
        pos.x = -10;
      }
      const maxH = parentBottom - profileActionsRef.current.offsetHeight + height;
      if (bottom > maxH) {
        pos.y = maxH - bottom;
      } else if (top < parentTop + 50) {
        pos.y = parentTop - top;
      } else {
        pos.y = -50;
      }
      setPosition(oldPos => ({ ...oldPos, ...pos }));

      let needleArrowPos = (pos.y * -1) + (height / 2);
      needleArrowPos = Math.min(profileActionsRef.current.offsetHeight - height - 10, Math.max(needleArrowPos, 0));
      setArrowPosition({
        y: needleArrowPos
      });
    }
  }, [button, parentRef]);

  const {
    setVisible,
    setMenu,
    setMessage,
    setFocus
  } = useBetween(VisibleLeftSidebarStates);

  const { handleClearAll } = useBetween(BurgerStates);

  const handleMessageClick = useCallback((value = true) => {
    if (authenticated) {
      setVisibleProfileAction(null);
      setMessage(oldMessage => {
        const tag = `user:${nickname}`;
        const message = oldMessage ?? '';
        if (message.includes(tag)) {
          return oldMessage;
        }
        return 'user:' + nickname + ',&nbsp;' + message;
      });
      setFocus(true)
      if (placement === "referrals" || placement === "bonus") {
        setVisible(true)
        setMenu("Chat")
      }
      if (placement === "bonus") setGameBonusVisible(false);
    } else {
      closableNotification(errorsT('Full authentication is required to access this resource.'), 'error');
    }
  }, [authenticated, setVisibleProfileAction, setVisible, setMenu, setMessage, setFocus, errorsT]);

  const handleTipClick = () => {
    setVisibleProfileAction(null);
    setNickname(nickname);
    setVisibleTipWindow(true);
  };

  const handleUserInfoClick = () => {
    setVisibleProfileAction(null);
    setNickname(nickname);
    setVisibleUserInfoModal(true);
  };

  const handleModeratorsInfoClick = () => {
    setVisibleProfileAction(null);
    setNickname(nickname);
    setVisibleModeratorsInfoModal(true);
  };

  const handleBanListClick = () => {
    setVisibleProfileAction(null);
    setNickname(nickname);
    setVisibleBanListModal(true);
  };

  const handleBanUserClick = () => {
    setVisibleProfileAction(null);
    setNickname(nickname);
    setVisibleBanUserModal(true);
  };

  const handleUserBanListClick = () => {
    setVisibleProfileAction(null);
    setNickname(nickname);
    setVisibleUserBanListModal(true);
  };

  const handleVisiblePrivilegesVipClick = () => {
    setVisibleProfileAction(null);
    setNickname(nickname);
    setVisiblePrivilegesVipModal(true);
  };

  const handleVisiblePrivilegesLegClick = () => {
    setVisibleProfileAction(null);
    setNickname(nickname);
    setVisiblePrivilegesLegModal(true);
  };

  const deleteMessage = async () => {
    axios.delete(`${process.env.REACT_APP_MODERATOR_LINK}/messages/delete-message/${messageData.id}`, userAuthenticationConfig()).then(response => {
      closableNotification(t("messageDeletedSuccessfully"), "success");
      setVisibleProfileAction(null);
      setMessageData(null);
    }).catch(error => {
      closableNotification(errorsT(error.response.data.message), "error");
      setVisibleProfileAction(null);
      setMessageData(null);
    });
  };

  const arrowStyles = {
    top: arrowPosition.y,
    left: -20
  };

  const posStyles = {
    left: position.x,
    top: position.y
  }

  useEffect(() => {
    if (calcPosTimeoutRef.current) {
      clearTimeout(calcPosTimeoutRef.current);
    }

    calcPos();

    if (isInitialRender && placement === "chat" && visibleProfileAction === keys) {

      calcPosTimeoutRef.current = setTimeout(() => {
        calcPos();
        setIsInitialRender(false);
      }, 300);
    }

    const element = parentRef?.current;
    if (element) {
      element.addEventListener('scroll', calcPos);
    }
    window.addEventListener('resize', calcPos);

    return () => {
      if (calcPosTimeoutRef.current) {
        clearTimeout(calcPosTimeoutRef.current);
      }
      if (element) {
        element.removeEventListener('scroll', calcPos);
      }
      window.removeEventListener('resize', calcPos);
    }
  }, [calcPos, parentRef, profileActionsRef, visibleProfileAction, keys, isInitialRender, placement]);

  useEffect(() => {
    calcPos();
    return () => {
      setVisibleProfileAction(false);
    }
  }, []);

  const openUserProfile = () => {
    history.push(`${generatePath(`/account/${nickname}`)}`);
    setVisible(false);
    setMenu("");
    if (placement === "bonus") setGameBonusVisible(false);
    handleClearAll();
  }

  return (
    <>
      <StyledWrapper ref={profileActionsRef} posStyles={posStyles} arrowStyles={arrowStyles}
                     visible={visibleProfileAction === keys && place === placement}
                     isChat={placement === "chat"}
                     isGame={placement === "dice" || placement === "balls" || placement === "roulette"}
                     isRain={placement === "rain"}
                     isTip={placement === "tip"}
                     isBetPage={placement === "betPage"}
                     isContests={placement === "contests"}
                     isReferrals={placement === "referrals"}
                     isBonus={placement === "bonus"}
      >
        <NavLink to={`${generatePath(`/account/${nickname}`)}`} onClick={openUserProfile} onTouchEnd={(e) => e.stopPropagation()}>{t('profile')}</NavLink>
        <NavLink to="#" disabled>{t('addToFriends')}</NavLink>
        <NavLink to="#" onClick={handleMessageClick} onTouchEnd={e => e.stopPropagation()}>{t('message')}</NavLink>
        <NavLink to="#" disabled>{t('privateMessage')}</NavLink>
        <NavLink to="#" onClick={handleTipClick} onTouchEnd={e => e.stopPropagation()}>{t('sendTip')}</NavLink>
        <NavLink to="#" disabled>{t('addToIgnore')}</NavLink>
        {(user?.roles[0] === roles.MODERATOR || user?.roles[0] === roles.ADMIN) &&
        <>
          <NavLink to="#" onClick={handleUserBanListClick} onTouchEnd={e => e.stopPropagation()}>{t('banList')}</NavLink>
          <NavLink to="#" onClick={handleBanUserClick} onTouchEnd={e => e.stopPropagation()}>{t('banAccount')}</NavLink>
          <NavLink to="#" onClick={handleBanListClick} onTouchEnd={e => e.stopPropagation()}>{t('blockedAccounts')}</NavLink>
          {messageData && <NavLink to="#" onClick={deleteMessage} onTouchEnd={e => e.stopPropagation()}>{t('deleteMessage')}</NavLink>}
          <NavLink to="#" onClick={handleModeratorsInfoClick} onTouchEnd={e => e.stopPropagation()}>{t('moderators')}</NavLink>
          <NavLink to="#" onClick={handleUserInfoClick} onTouchEnd={e => e.stopPropagation()}>{t('information')}</NavLink>
          {user?.roles[0] === roles.ADMIN && <NavLink to="#" onClick={handleVisiblePrivilegesVipClick} onTouchEnd={e => e.stopPropagation()}>{t("giveVipBtn")}</NavLink>}
          {user?.roles[0] === roles.ADMIN && <NavLink to="#" onClick={handleVisiblePrivilegesLegClick} onTouchEnd={e => e.stopPropagation()}>{t("giveLegendBtn")}</NavLink>}
        </>
        }
      </StyledWrapper>
    </>
  );
}

export default ProfileActionsWindow;