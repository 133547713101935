import Dialog from "rc-dialog";
import LoadButton from "../../spinner/ButtonSpinner";
import {StyledButton} from "../../../styles/styledButton";
import AlertMessage from "../../alert/Alert";
import {useEffect, useRef, useState} from "react";
import {
    ExchangeConfirmModalContainer,
    ExchangeInfoContainer,
    ExchangeInfoItem,
    ExchangeInfoTitle,
    ExchangeInfoValue,
} from "./styledConfirmExchangeModal";
import {useTranslation} from "react-i18next";
import http from "../../../../http";
import userAuthenticationConfig from "../../../../utils/userAuthenticationConfig";
import {closableNotification} from "../../notification/ClosableNotification";
import Big from "big.js";
import {useBetween} from "use-between";
import BalanceStates from "../../../games/BalanceStates";

function ConfirmExchangeModal({setExchangeData, exchangeData, setVisibleExchangeModal}) {
    const {t} = useTranslation('siteOptions');
    const {t: errorsT} = useTranslation("errors");

    const [isLoading, setIsLoading] = useState(false);
    const [isExpiredSwap, setIsExpiredSwap] = useState(false);

    const {setBalance, setActiveCurrency, setPaymentMethods} = useBetween(BalanceStates);

    const [timeLeft, setTimeLeft] = useState(null);
    const intervalRef = useRef(null);

    useEffect(() => {
        if (exchangeData?.expireAt) {
            const updateRemainingTime = () => {
                const now = Math.floor(Date.now() / 1000);
                const diff = exchangeData.expireAt - now;
                if (diff <= 0) {
                    setIsExpiredSwap(true);
                    setTimeLeft(0);
                    clearInterval(intervalRef.current);
                } else {
                    setTimeLeft(diff);
                }
            };

            updateRemainingTime();
            intervalRef.current = setInterval(updateRemainingTime, 1000);

            return () => clearInterval(intervalRef.current);
        }
    }, [exchangeData]);

    const confirmExchangeReq = async () => {
        setIsLoading(true);
        const { id, from, give, to, receive } = exchangeData || {};

        try {
            await http.post(
                `${process.env.REACT_APP_EXCHANGE_LINK}/api/swap/confirm`,
                { id },
                { ...userAuthenticationConfig(false) }
            );
            updateBalances(from, give, "minus");
            updateBalances(to, receive, "plus");
            setExchangeData(null);
            setVisibleExchangeModal(false);
            closableNotification(t("successExchange"), "success");
        } catch (error) {
            if (error?.response?.data?.error_message === "Swap expired") setIsExpiredSwap(true);
            closableNotification(errorsT(error?.response?.data?.error_message), "error");
        } finally {
            setIsLoading(false);
        }
    };

    const newExchangeReq = async () => {
        setIsLoading(true);
        const data = {
            "from": exchangeData?.from,
            "to": exchangeData?.to,
            "amount": (new Big(exchangeData?.give)).toNumber(),
        }
        http.post(`${process.env.REACT_APP_EXCHANGE_LINK}/api/swap/estimate`, data, {...userAuthenticationConfig(false)})
            .then(response => {
                setExchangeData(response?.data);
                setIsExpiredSwap(false);
                setIsLoading(false);
            })
            .catch(error => {
                closableNotification(errorsT(error?.response?.data?.error_message), "error");
                setIsLoading(false);
            });
    };

    const updateBalances = (asset, amount, type) => {
        setActiveCurrency((prevActiveCurrency) => {
            if (prevActiveCurrency === asset) {
                setBalance((prevState) => calculateNewBalance(prevState, amount, type));
            }
            return prevActiveCurrency;
        });

        setPaymentMethods((prevState) =>
            prevState?.map((paymentMethod) => {
                if (paymentMethod?.currency?.asset === asset) {
                    return {
                        ...paymentMethod,
                        balances: {
                            ...paymentMethod.balances,
                            amount: calculateNewBalance(paymentMethod.balances.amount, amount, type)
                        }
                    };
                }
                return paymentMethod;
            })
        );
    };

    const calculateNewBalance = (current, amount, type) => {
        const currentBig = new Big(current ?? 0);
        const amountBig = new Big(amount);
        return type === "plus"
            ? currentBig.plus(amountBig).toFixed(8)
            : currentBig.minus(amountBig).toFixed(8);
    };

    return (
        <Dialog
            visible={exchangeData}
            wrapClassName="default-modal-wrapper"
            onClose={() => setExchangeData(null)}
            animation="zoom"
            maskAnimation="fade"
            title={t("confirmExchange")}
            forceRender={false}
            className="default-modal"
            footer={isLoading ? <LoadButton
                text={isExpiredSwap ? t("updateExchange") : t("confirmExchange")}
                color="neutral"
                type="submit"
                style={{width: '100%'}}
            /> : <StyledButton
                onClick={isExpiredSwap ? newExchangeReq : confirmExchangeReq}
                color="neutral"
                type="submit"
                width="100"
            >{isExpiredSwap
                ? t("updateExchange")
                : `${t("confirmExchange")} ${timeLeft !== null ? `(${timeLeft} ${t("sec")})` : ""}`}</StyledButton>}
        >
            <ExchangeConfirmModalContainer>
                <ExchangeInfoContainer>
                    <ExchangeInfoItem>
                        <ExchangeInfoTitle>{t("youGive")}</ExchangeInfoTitle>
                        <ExchangeInfoValue>{exchangeData?.give} {exchangeData?.from}</ExchangeInfoValue>
                    </ExchangeInfoItem>
                    <ExchangeInfoItem>
                        <ExchangeInfoTitle>{t("youGet")}</ExchangeInfoTitle>
                        <ExchangeInfoValue>{exchangeData?.receive} {exchangeData?.to}</ExchangeInfoValue>
                    </ExchangeInfoItem>
                    <AlertMessage
                        type="warning"
                        message={t("confirmExchangeAlert")}
                        mt={15}
                    />
                </ExchangeInfoContainer>
            </ExchangeConfirmModalContainer>
        </Dialog>
    )
}

export default ConfirmExchangeModal;