import React, {useContext, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useBetween} from "use-between";

import ProfileActionsStates from "../../ProfileActionsStates";
import Dialog from "rc-dialog";
import Select, {Option} from "rc-select";
import ReCaptcha from "../../../elements/reCaptcha/ReCaptcha";
import getBanTimeVariants from "../../../../constants/banTimeVariants";

import {StyledButton} from "../../../styles/styledButton";
import {StyledWrapper} from "./styledPrivilegesModal";
import {StyledSelect, StyledSelectLabel} from "../../../styles/styledSelect";

import {AppContext} from "../../../../App";
import axios from "axios";
import userAuthenticationConfig from "../../../../utils/userAuthenticationConfig";
import {closableNotification} from "../../../elements/notification/ClosableNotification";
import LoadButton from "../../../elements/spinner/ButtonSpinner";

function PrivilegesModal({activeNickname}) {
    const {t} = useTranslation("siteOptions");
    const {t: tE} = useTranslation('errors');

    const variantsBanTime = getBanTimeVariants(t);

    const [selectedVariantStatusTime, setSelectedVariantStatusTime] = useState(1);
    const [isLoading, setIsLoading] = useState(false);

    const {
        visiblePrivilegesVipModal,
        setVisiblePrivilegesVipModal,
        visiblePrivilegesLegModal,
        setVisiblePrivilegesLegModal
    } = useBetween(ProfileActionsStates);
    const {authenticated} = useContext(AppContext);

    const recaptchaRef = useRef(null);

    const giveUserPrivileges = () => {
        const data = {
            nickname: activeNickname,
            status_time: selectedVariantStatusTime,
            type: visiblePrivilegesVipModal ? "vip" : "legend",
            captchaToken: recaptchaRef?.current?.getValue()
        }
        setIsLoading(true);
        recaptchaRef?.current?.reset();
        axios.post(`${process.env.REACT_APP_MODERATOR_LINK}/users/give-privileges`, data, userAuthenticationConfig(false)).then(response => {
            closableNotification(t("successGiveStatus"), "success");
            setIsLoading(false);
            setVisiblePrivilegesVipModal(false);
            setVisiblePrivilegesLegModal(false);
        }).catch(error => {
            closableNotification(tE(error.response.data.message), "error");
            setIsLoading(false);
        });
    };

    return (
        <Dialog
            visible={authenticated && (visiblePrivilegesVipModal || visiblePrivilegesLegModal)}
            wrapClassName="default-modal-wrapper"
            onClose={() => visiblePrivilegesVipModal ? setVisiblePrivilegesVipModal(false) : setVisiblePrivilegesLegModal(false)}
            animation="zoom"
            maskAnimation="fade"
            title={`${visiblePrivilegesVipModal ? t("giveVipTitleModal") : t("giveLegTitleModal")} «${activeNickname}»`}
            forceRender={false}
            className="default-modal ban-user-modal"
            style={{margin: "auto"}}
        >
            <StyledWrapper>
                <StyledSelect style={{position: "relative"}} id={"status-time"}>
                    <StyledSelectLabel>{t("statusTime")}:</StyledSelectLabel>
                    <Select
                        getPopupContainer={() => document.getElementById("status-time")}
                        className="custom-select"
                        name="category"
                        value={selectedVariantStatusTime}
                        onChange={(value) => setSelectedVariantStatusTime(value)}
                        virtual={false}
                        dropdownAlign={{offset: [-1, 12]}}
                        defaultValue={selectedVariantStatusTime}
                    >
                        {variantsBanTime?.map((variant, key) => (
                            <Option
                                value={variant.value}
                                key={key}
                            >
                                <div className="option-select-item">
                                    {variantsBanTime.find((option) => option.value === variant.value).title}
                                </div>
                            </Option>
                        ))}
                    </Select>
                </StyledSelect>
                <ReCaptcha recaptchaRef={recaptchaRef}/>
                {isLoading ? <LoadButton
                    text={t("give")}
                    color="neutral"
                    type="submit"
                    style={{width: '100%', margin: '0'}}
                /> : <StyledButton
                    onClick={giveUserPrivileges}
                    color="neutral"
                    type="submit"
                    style={{width: '100%', margin: '0'}}
                    disabled={!selectedVariantStatusTime === ""}
                >
                    {t("give")}
                </StyledButton>}
            </StyledWrapper>
        </Dialog>
    )
}

export default PrivilegesModal;