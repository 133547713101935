import styled, { css, keyframes } from "styled-components";

export const ExchangeConfirmModalContainer = styled.div`
    position: relative;
`

export const Line = styled.div`
    display: flex;
    height: 1px;
    width: 100%;
    background: ${({theme}) => theme.mainTextColor};
    border-radius: 100%;
`

export const ExchangeInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    
    .input-group {
        margin-bottom: 10px;
        margin-top: 10px;
    }
`

export const ExchangeInfoItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-bottom: 15px;
    font-size: 18px;
`

export const ExchangeInfoTitle = styled.span`
    color: ${({theme}) => theme.mainTextColor};
    font-size: 18px;
`

export const ExchangeInfoValue = styled.span`
    color: ${({theme}) => theme.secondTextColor};
    max-width: 80%;
    word-wrap: break-word;
    overflow-wrap: break-word;
`
